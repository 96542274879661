<template>
    <div class="main" v-if="showContent">
        <div class="left-side" :style="{ width: leftWidth + '%' }">
            <SideBar ref="sidebar" @clickGroup="enterRoom"></SideBar>
        </div>
        <div v-if="leftWidth != 100 && leftWidth != 0" class="resize" :style="{ left:leftWidth + '%' ,width: '20px' }" @mousedown="startResize">
        </div>
        <div class="main-content" :style="{ width: mainWidth + '%' }">
            <ChatPage 
                v-if="!!urlid && updateFlag" 
                :urlid="urlid" 
                :nickname="nickname"
                @quite="leaveRoom"
                @updateHistory="updateHistory"
            ></ChatPage>
            <HomePage v-else :name="nickname"></HomePage>
        </div>
    </div>
</template>

<script>
import ChatPage from '../components/ChatPage.vue';
import SideBar from '../components/SideBar.vue';
import HomePage from '../components/HomePage.vue';

import {postLogin} from "../func/requestHandler";
import {getCookie, setCookie} from "../func/util" // setCookie

export default {
    components:{
        ChatPage,
        SideBar,
        HomePage
    },
    data(){
        return{
            nickname: "",
            resizing: false,
            startX: 0,
            initialLeftWidth: getCookie('initialLeftWidth'), // 初始左侧宽度百分比
            updateFlag: true,

            screenWidth: 0,
            screenHeight: 0,

            showContent: false,
        }
    },
    computed: {
        leftWidth() {
            if(this.screenWidth > this.screenHeight){ // 横屏
                return this.initialLeftWidth*1 || 30;
            }else{ // 竖屏 如果有urlid则chatpage 100%
                if( this.urlid ){ // 展示chatpage sidebar 宽度为0
                    return 0;
                }else{
                    return 100;
                }
            }
        },
        mainWidth() {
            return 100 - this.leftWidth;
        },
        urlid(){
            return this.$route.params.id
        }
    },
    methods: {
        enterRoom(urlid){
            if( urlid == this.urlid ){
                return;
            }

            this.$router.push({name: "chatRoom", params:{id: urlid}});
            
            this.updateRoom();
        },
        leaveRoom(){
            this.$router.push({path: "/"});
            this.updateRoom();
        },
        updateRoom(){
            this.updateFlag = false;
            this.$nextTick(()=>{
                this.updateFlag = true;
            })
        },
        updateHistory(){
            this.$refs.sidebar.updateHistoryList();
        },
        async login(){
            var uid = getCookie('uid');
            var uniqueid = getCookie('uniqueid');
            if(uid == null || uniqueid == null){
                //如果本地uid或uniqueid没被初始化过，传入初始0值，登录请求会返回新注册uid和uniqueid
               uid = 0;
               uniqueid = "";
               console.log("新注册了账号，uid:"+uid)
            }
             
            //开始登录，无论是否有本地登录记录，都要请求网络，更新用户信息或新注册
            var result = await postLogin(uid,uniqueid)
            if(result != null){
                // this.avatar = result.nickname[0]
                this.nickname = result.nickname
                this.oldname = result.nickname
            }

            this.showContent = true;
        },
        //返回，0横屏，1竖屏
        judgeScreen(){
            const width = window.innerWidth;  
            const height = window.innerHeight;
            if(width > height){
                return 0
            }else{
                return 1
            }
        },
        startResize(e) {
            this.resizing = true;
            this.startX = e.clientX;
            document.addEventListener('mousemove', this.resize);
            document.addEventListener('mouseup', this.stopResize);
        },
        resize(e) {
        if (this.resizing) {
            const deltaX = e.clientX - this.startX;
            const totalWidth = document.documentElement.clientWidth;
            const newLeftWidth =
            (this.leftWidth * totalWidth + deltaX * 100) / totalWidth;
            if (newLeftWidth >= 20 && newLeftWidth <= 50) {
            this.initialLeftWidth = newLeftWidth;
            }
            this.startX = e.clientX;
        }
        },
        stopResize() {
            this.resizing = false;
            document.removeEventListener('mousemove', this.resize);
            document.removeEventListener('mouseup', this.stopResize);
            setCookie('initialLeftWidth',this.initialLeftWidth);
        },
        getWindowInfo(){
            this.screenWidth = window.innerWidth;
            this.screenHeight = window.innerHeight;
        }
    },
    mounted() {
        //this.leftWidth = 100;//默认展示空白首页，以优先兼容移动端
        this.resizeWidth = this.initialResizeWidth;
    },
    async created(){
        await this.login()
        console.log("route", this.$route)

        this.getWindowInfo();
        window.addEventListener('resize', this.getWindowInfo);
    }
}
</script>

<style lang="scss" scoped>
    .main{
        display: flex;
        height: 100%;

        .left-side{
            position: relative;
            height: 100%;
            overflow: hidden;
        }
        .resize{
            position: absolute;
            height: 100%;
            background: rgba(246, 202, 51,0);
            cursor: col-resize;
            z-index: 999;
            user-select: none; /* 禁用文字选择 */
        }
        .main-content{
            position: relative;
            overflow: hidden;
        }
    }
</style>../components/ChatPage.vue