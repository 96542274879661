<template>
    <div class="sidebar">
        <div class="header">
            <img class="logo" src="../assets/logo.png" />
            <div>在此群聊</div>
            <el-dropdown class="menubtn" @command="handleMenu">
                <div><img src="../assets/menu.png" /><i class="el-icon-arrow-down el-icon--right"></i></div>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="createGroup">创建普通群</el-dropdown-item>
                    <el-dropdown-item command="createPaidGroup">创建付费群</el-dropdown-item>
                    <el-dropdown-item command="getApp">下载APP</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <!-- <div v-if="isShowApp" class="appbtn" @click="getApp()"><img src="../assets/app.png" /></div> -->
        </div>
        <div class="main">
            <input placeholder="输入群名，加入或创建群聊" v-model="searchText" @input="updateSearchText" @keydown.enter="joinGroup(searchText)"/>
            <div class="list" v-if="list.length > 0">
                <div class="list-item" v-for="(item, index) in list" :key="index" @click="joinGroup(item)">
                    <div class="avatar">{{item[0]}}</div>
                    <div class="name">{{item}}</div>
                    <!-- <div v-if="itemStatuses[item]" class="isactive">有人活跃</div>   -->
                    <img v-if="searchText=='' && tab == 1" class="delete" src="../assets/delete.png" @click.stop="deleteHistoryGroupFromCookie(item)" />
                    <div v-if="searchText!='' && isNewGroup && index==0" class="create">+ 创建群</div>
                </div>
                <div class="end-tip">- THE END -</div>
            </div>
            <div v-else class="empty-tip">
                <div>暂无群聊</div>
                <div>在上方输入任意群名加入群聊吧</div>
            </div>
        </div>
        <div v-if="!searchText" class="tabs">
            <div :class="{selected: tab==1}" @click="selectTab(1)">我的群聊</div>
            <div :class="{selected: tab==2}" @click="selectTab(2)">推荐群聊</div>
        </div>
    </div>
</template>

<script>

import {getCookie,setCookie,addHistoryGroup,getHistoryGroup,deleteHistoryGroup} from "../func/util"
import {postJoinGroup,postHotGroup, getSearchResult,getGroupListStatus} from "../func/requestHandler" // 


export default {
    data(){
        return {
            tab: 1,//默认tab，默认为“我的群
            list: [],//页面list结构：[xxxx,xxxxx,xxxx]
            itemStatuses: {}, //使用对象来存储状态，以list的item作为标识，如{"群名1":111,"群名2":222} 
            joingname: "",
            searchText: "",
            isNewGroup: false,
            isShowApp:true, //是否显示下载app的按钮
            nowSearching:"",//记录当前搜索的词，用来避免快速删除或打字的情况

        };
    },
    watch: {  
        list: {  
            handler() {  
                this.updateListItemStatuses();
            },  
            deep: true, // 如果list是嵌套对象或数组，需要深度监听  
        },
    },
    methods:{
        async updateListItemStatuses() {
            var that = this
            getGroupListStatus(that.list, (result)=>{
                if(result.succeed){
                    that.itemStatuses = {}//todo：接口暂未实现，需要服务单实现后再说
                }else{
                    console.log(result.message)
                }
            })  
        },  
        async joinGroup(gname){
            if(gname == ""){
                return
            }
            

            //通过groupname请求groupid
            console.log("gname", gname)
            var result = await postJoinGroup(gname)
            //跳转页面
            if(result.succeed == true){
                
                var urlid = result.gid.toString(36);
                this.$emit("clickGroup", urlid)

                //增加历史数据
                addHistoryGroup(gname)

                //如果当前是从最近群聊进来的，需要刷新一下最新数据
                if(this.tab == 1 && this.searchText == ""){
                    this.selectTab(1)
                }
                
                //如果是从搜索进入群聊
                if(this.searchText != ""){
                    this.searchText =""
                    this.selectTab(1)//搜索后默认锚定到我的tab
                }
                
                
            }else{
                this.$message.error('此群聊暂未开放，聊点别的吧~');
            }
        },
        async selectTab(tab){
            this.searchText = "";
            if( tab ){
                this.tab = tab;
                setCookie('defaulttab',tab+"")
            }
            console.log("tab值：",tab)
            if( this.tab == 1 ){ // history group
                this.updateHistoryList();
            }else{ // hot group
                await postHotGroup((arr)=>{
                    this.list = arr
                })
            }
        },
        updateSearchText(e){
            
            let val = e.target.value;
            console.log("updateSearchText")
            
            //如果输入以#号开头的内容，自动弹出输入群id进群的弹框
            var that = this
            if(val.includes("#")){
                that.searchText = ''
                that.$prompt( '','群口令进群', {
                confirmButtonText: '立即进群',
                inputPlaceholder:"输入群口令，立即进群",
                cancelButtonText: '取消',
                closeOnClickModal:false,
                center:true
                }).then(({ value }) => {
                //输入code后
                    let processedVal = value.replace(/#/g, '');
                    console.log("code:",processedVal)
                    that.$emit("clickGroup", processedVal)
                    
                }).catch(() => {
                    //不做任何事  
                });

                return
            }
            //如果不是以#号开头的，再处理别的
            //解决时序问题
            this.searchText = val;
            this.nowSearching = val;
            if( val ){
                this.isNewGroup = false
                getSearchResult(val, (result)=>{
                    //异步请求时保持时序用，防止时序错误的回调
                    if(val != this.nowSearching){
                        return
                    }
                    if( !result.some(item=>item==val) ){
                        result.unshift(val);
                        this.isNewGroup = true;
                    }
                    this.list = result;
                })
            }else{
                this.selectTab(this.tab);
            }
        },
        updateHistoryList(){
            console.log("updateHistoryList")
            this.list = getHistoryGroup()
        },
        deleteHistoryGroupFromCookie(gname){
            deleteHistoryGroup(gname)
            //refresh history
            this.updateHistoryList();
        },
        handleMenu(command){
            switch(command){
                case "createGroup":
                    this.createGroup();
                    break;
                case "createPaidGroup":
                    this.createPaidGroup();
                    break;
                case "getApp":
                    this.getApp();
                    break;
            }
        },
        createGroup(){
            var that = this
            //var oldname = this.info.senderName;
            
            that.$prompt( '请输入群名','创建普通群', {
                confirmButtonText: '创建群聊',
                inputPlaceholder:"输入群名，不超过10个字",
                inputPattern:/^.{1,10}$/,
                inputErrorMessage:"群名不能超过10个字符",
                cancelButtonText: '取消',
                closeOnClickModal:true,
                center:true
            }).then(async ({ value }) => {
                //如果输入为空，不做任何处理，恢复成旧名字
                that.joinGroup(value)        
            }).catch(() => {
                //不做任何事  
            });
        },
        createPaidGroup(){
            //this.$router.push('/creator');
            window.open('/creator', '_blank');
        },
        getApp(){
            let p = navigator.platform;
            let u = navigator.userAgent;
            let is_android = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;
            let is_ios = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
            if (p == "Win32" || p == "Win64" || p == "MacPPC" || p == "MacIntel" || p == "X11" || p == "Linux i686") {//PC调试环境
                //web端弹窗
                this.$alert('<img class="shareimg" src="https://cikephoto.oss-cn-hangzhou.aliyuncs.com/resources/appqr.png" width="100%"  />', '', {
                dangerouslyUseHTMLString: true,
                closeOnClickModal:true,
                closeOnPressEscape:true,
                center:true,
                roundButton:true,
                showConfirmButton:false
                });
                return
            }
            else {
                if (is_android) {//Android终端
                    //点击下载app
                    let routeData = this.$router.resolve({ path: '/download'});
                    window.open(routeData.href, '_blank');
                }
                else if (is_ios) {//IOS终端
                    //点击下载app
                    let routeData = this.$router.resolve({ path: '/download'});
                    window.open(routeData.href, '_blank');
                }
            }
        }
    },
    async created(){
        //初始化默认选择的tab
        var defaulttab  = getCookie('defaulttab');
        console.log("sidebar defaulttab",defaulttab)
        if(defaulttab == "1"){
            this.selectTab(1);
        }else{
            this.selectTab(2);
        }

        //隐藏按钮
        let is_custom_android_app = navigator.userAgent.indexOf('zaiciappadr') > -1; // 根据自定义User-Agent标识来判断是否是你的安卓客户端

        if (is_custom_android_app) {
            // 在安卓客户端内置浏览器中，隐藏按钮
            this.isShowApp=false
        }
    }
}
</script>

<style lang="scss" scoped>
.sidebar{
    height: 100%;
}
.header{
    background: #fff;
    position: relative;
    height: 60px;

    .logo{
        width: 40px;
        height: 40px;
        border-radius: 10px;
        position: absolute;
        left: 10px;
        top: 10px;
    }
    .menubtn{
        width: 28px;
        height: 28px;
        position: absolute;
        top: 8px; 
       right: 10px; 
       user-select: none; /* 禁用文字选择 */

       img{
        width: 28px;
        height: 28px;
       }
    }
    div{
        width: 100%;
        font-size: 20px;
        color: rgb(102, 102, 102);
        line-height: 60px;
        text-align: center;
    }
}
.main{
    position: relative;
    height: calc(100% - 60px);
    width: 100%;
    background: #F6F6F6;
    position: relative;

    input {
        width: 80%;
        margin: 15px 10% 5px 10%;
        border: none;
        border-radius: 15px;
        padding: 10px 0;
        font-size: 16px;
        text-align: center;
        background-color: #ffffff;

        &::placeholder{
            color: rgb(204, 204, 204);
        }
    }
    input:focus-visible{
        outline: none;
    }
    .list{
        height: calc(100% - 120px);
        padding-bottom: 66px;
        overflow-y: scroll;
        overflow-x: hidden;
        scrollbar-width: none;

        &::-webkit-scrollbar{
            display: none;
        }
    }
    .list-item{
        display: flex;
        padding: 15px 5%;
        border-bottom: 1px solid #F6F6F6;
        .avatar2{
            width: 48px;
            height: 45px;
            line-height: 45px;
            text-align: center;
            color: #ffffff;
            font-size: 16px;
            background-color: #c2eda9;
            border-radius: 5px;
            flex-shrink: 0;
            user-select: none; /* 禁用文字选择 */
            cursor:pointer;
        }
        .avatar{
            width: 48px;
            height: 45px;
            line-height: 45px;
            text-align: center;
            color: #b1b1b1;
            font-size: 16px;
            background-color: #ebebeb;
            border-radius: 5px;
            flex-shrink: 0;
            user-select: none; /* 禁用文字选择 */
            cursor:pointer;
        }
        .name{
            color: #333333;
            font-size: 16px;
            width: calc(90% - 45px);
            line-height: 45px;
            text-align: left;
            padding: 0 18px;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: keep-all;
            cursor: pointer;
        }
        .delete{
            width: 20px;
            height: 20px;
            padding: 12px 0;
            cursor: pointer;
            user-select: none; /* 禁用文字选择 */
        }
        .create{
            font-size: 14px;
            color: rgb(162, 211, 133);
            width: 74px;
            line-height: 45px;
            flex-shrink: 0;
            cursor: pointer;
        }
    }
    .end-tip{
        margin: 20px 0 10px 0;
        font-size: 10px;
        color: rgb(210, 210, 210);
        user-select: none; /* 禁用文字选择 */
        /* 创建线性渐变背景 */  
        background: linear-gradient(to bottom, rgb(210, 210, 210),rgb(240, 240, 240) );  
        /* 使用背景剪辑使文字呈现渐变效果 */  
        background-clip: text;  
        -webkit-background-clip: text; /* For Safari and Chrome */  
        -moz-background-clip: text; /* For Firefox */  
        -ms-background-clip: text; /* For Internet Explorer */  
        -o-background-clip: text; /* For Opera */  
        color: transparent;  
    }

    .empty-tip{
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        color: rgb(204, 204, 204);
        text-align: center;

        div:nth-of-type(1){
            font-size: 20px;
        }
        div:nth-of-type(2){
            font-size: 12px;
        }
    }
}
.tabs{
    display: flex;
    position: absolute;
    width: 100%;
    bottom: 0;

    div {
        color: #666;
        background: #fff;
        font-size: 14px;
        width: 50%;
        height: 60px;
        line-height: 60px;
        user-select: none; /* 禁用文字选择 */

        &:hover{
            cursor: pointer;
        }

        &.selected{
            color: rgb(246, 202, 51);
            background: #FCFCFC;
            font-weight: bold;
            font-size: 16px;
        }
    }
}
</style>