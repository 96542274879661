<template>
    <el-drawer
        title="登录管理账号"
        :before-close="handleClose"
        :visible="visible"
        direction="btt"
        custom-class="drawerclass"
        size= "auto"
        ref="drawer"
        >
        <div class="drawerintro">请先创建一个付费群，并使用建群时的邮箱登录</div>
        <div class="drawerbody">
          <el-form :model="loginform">
            <el-form-item label="管理邮箱" :label-width="formLabelWidth" >
              <el-input v-model="loginform.email" type="email" autocomplete="off" clearable placeholder="填写创建过付费群的邮箱地址"></el-input>
            </el-form-item>
            <el-form-item label="付费群名" :label-width="formLabelWidth" >
              <el-input v-model="loginform.gname" type="text" maxlength="10" show-word-limit autocomplete="off" placeholder="该邮箱下任意一个付费群的群名" clearable></el-input>
            </el-form-item>
          </el-form>
          <div class="demo-drawer__footer">
            <el-button @click="cancelForm">取 消</el-button>
            <el-button type="primary" @click="handleLogin" :loading="loading">{{ loading ? '提交中 ...' : '登录' }}</el-button>
          </div>
        </div>
      </el-drawer>
</template>

<script>
import {getLoginEmail, postCreatorEmailLogin} from "@/func/creatorHandler";

export default{
  props: ['visible'],
  data(){
      return {
        formLabelWidth: '20%',
        loading: false,
        //登录弹窗数据结构
        loginform:{
          email:'',
          gname:''
        },
      };
  },
  methods: {
    handleLogin() {
      if(getLoginEmail()){
        //先看看本地有无登录状态记录
        //登录成功后获取用户数据
        this.$emit('handleGetHomeData')
      }
      else{
        this.loading= true;
        const email = this.loginform.email;
        const gname = this.loginform.gname;
        postCreatorEmailLogin(email, gname, (result) => {
          this.loading = false; // 结束加载
          if (result.succeed) {
            // 登录成功
            this.$message.success('登录成功');
            
            //登录成功后获取用户数据
            this.$emit('handleGetHomeData')
          } else {
            // 登录失败
            this.$message.error(result.message || '登录失败');
          }
        });
      }
    },
    handleClose(done) {
      done()
      this.$emit("close", false)
    },
    cancelForm(){
      this.$emit("cancelForm", 'logindialog')
    },
  }
}
</script>

<style scoped>
/*++++++弹窗样式表：++++++++*/
::v-deep .drawerclass .el-drawer__header {
    margin-bottom: 5px;
    font-size: 20px;
    color: #333333;
    padding-right:0px;
    margin-left: 20px;
  } 
  .drawerbody{
    width: 100%;
    padding-top:20px;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 40px;
    line-height: 20px;
  }
  .drawerintro{
    margin:0 10% 5px 10%;
    font-size: 12px;
    color:#333333;
  }

  /* Drawer 基本样式调整 */
.succeeddrawer {
  padding: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
  line-height: 1.5;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

/* 成功提示文本样式 */
.succeeddrawer div {
  margin-bottom: 20px;
  font-size: 16px;
  text-align: center;
}

/* 成功图标动画 */
@keyframes popIn {
  0% {
    transform: scale(0); /* 开始时图标缩放为0 */
    opacity: 0; /* 开始时图标完全透明 */
  }
  50% {
    transform: scale(1.2); /* 中途图标放大到比原始尺寸稍大 */
    opacity: 1; /* 中途图标完全不透明 */
  }
  100% {
    transform: scale(1); /* 结束时图标恢复到原始尺寸 */
  }
}
/* 成功图标样式 */
.succeedicon {
  display: block;
  margin: 40px auto 30px; /* 增加下边距以增大图标与文案之间的间隙 */
  width: 60px; /* 根据需要调整图标大小 */
  height: auto;
  animation: popIn 0.6s ease-out; /* 应用动画 */
}
.succeedtext{
  margin:0 8% 0 8%;
}
/* 按钮样式 */
.succeeddrawer .el-button {
  display: block;
  margin: 0 auto;
  padding: 15px 40px;
  margin:30px auto 40px auto;
  font-size: 14px;
  color: #fff;
  background-color: #409EFF;
  border-color: #409EFF;
  border-radius: 30px; /* 圆角按钮 */
  transition: background-color .3s, border-color .3s, color .3s;
}

.succeeddrawer .el-button:hover {
  background-color: #66b1ff;
  border-color: #66b1ff;
  color: #fff;
}

/* Drawer 标题样式 */
.succeeddrawer .el-drawer__header {
  font-size: 18px;
  color: #333;
  text-align: center;
  border-bottom: 1px solid #ebeef5;
}
</style>