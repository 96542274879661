<!-- 创建群聊弹窗 -->
<template>
	<el-drawer
		title="申请创建付费群"
		:before-close="handleClose"
		:visible="visible"
		direction="btt"
		custom-class="drawerclass"
		size= "auto"
		ref="drawer"
		>
		<div class="drawerintro">免费创建付费群，每个邮箱最多可创建10个群</div>
		<div class="drawerbody">
			
			<el-form :model="createform">
				<el-form-item label="群名" :label-width="formLabelWidth" >
					<el-input v-model="createform.gname" type="text" maxlength="10" show-word-limit autocomplete="off" placeholder="限4-10个中文、字母或数字" clearable></el-input>
				</el-form-item>
				<el-form-item label="付费价格" :inline="true" :label-width="formLabelWidth" >
					<el-input v-model="createform.price" type="number" autocomplete="off" placeholder="如：2.99"><template slot="append">元</template></el-input >
				</el-form-item>
				<el-form-item label="群聊简介" :label-width="formLabelWidth">
					<el-input v-model="createform.intro" type="text" autocomplete="off" clearable placeholder="选填，不超过20个字" maxlength="20" show-word-limit ></el-input>
				</el-form-item>
				<el-form-item label="管理邮箱" :label-width="formLabelWidth" >
					<el-input v-model="createform.email" type="email" autocomplete="off" clearable placeholder="重要，填写邮箱地址"></el-input>
					<div style="line-height: 18px; color: #ff0000; text-align: left; margin-bottom:-15px;margin-top: 5px;">此邮箱用于接收群聊激活链接，及后续兑换奖品或提现，请务必仔细填写；</div>
				</el-form-item>
				<el-form-item label="确认邮箱" :label-width="formLabelWidth" >
					<el-input v-model="createform.emailcomfirm" type="email" autocomplete="off" placeholder="请再次输入管理邮箱" clearable></el-input>
				</el-form-item>
			</el-form>
			<div class="demo-drawer__footer">
				<el-button @click="cancelForm">取 消</el-button>
				<el-button type="primary" @click="handleCreate" :loading="loading">{{ loading ? '提交中 ...' : '创建付费群' }}</el-button>
			</div>
		</div>
	</el-drawer>
</template>

<script>
import {postCreatePaidGroup} from "@/func/creatorHandler";

export default{
	props: ['visible'],
	data(){
		return {
			//创建付费群弹窗的数据结构
			createform: {
				gname: '',
				price: '',
				intro: '',
				email: '',
				emailcomfirm:'',
			},
			formLabelWidth: '20%',
			loading: false,
		}
	},
	methods:{
		handleClose(done) {
			if (this.loading) {
				return;
			}
			//清空所有当前已有的列表历史数据
			//群列表弹窗数据结构

			this.$confirm('是否放弃提交？')
				.then(() => {
					this.timer = setTimeout(() => {
						done();
						this.$emit("close", false)
					}, 400);
				})
				.catch(()=> {});
		},
		cancelForm(){
			this.$emit("cancelForm", 'createdialog')
		},
		handleCreate() {
			if(this.createform.email != this.createform.emailcomfirm){
				//确认邮箱
				this.$message.error("两次输入的邮箱不一致，请检查后重试");
				return;
			}
			this.loading = true; // 开始加载
			const email = this.createform.email;
			const gname = this.createform.gname;
			const intro = this.createform.intro;
			const pricestr = this.createform.price;
			var price = 0
			// 使用正则表达式检查价格是否是一个2位小数以内的数
			const regex = /^\d+(\.\d{1,2})?$/;
			if (regex.test(pricestr)) {
				// 如果是2位小数以内的数，则乘以100变成整数
				price = parseFloat(pricestr) * 100;
			} else {
				// 如果小数位数大于2位，则报错
				this.$message.error('价格小数位数不能超过2位');
				this.loading = false; // 结束加载
				return
			}

			postCreatePaidGroup(email,gname,intro,price, (result) => {
				this.loading = false; // 结束加载
				if (result.succeed) {
					//申请成功
					//关闭现有的申请drawer
					this.$emit("close", false)

					//显示提示drawer
					this.$emit("showSuccessDrawer", {
						title: "群聊申请成功",
						text: "群聊申请成功！请前往管理邮箱（"+email+"），点击邮箱中的激活链接即可完成创建"//成功drawer的正文
					})
					
					// 申请成功
					//this.$message.success('群聊申请成功！请前往管理邮箱，点击邮箱中的激活链接即可完成创建');
				} else {
					// 登录失败
					this.$message.error(result.message || '系统错误，请重试');
				}
			});
		},
	}
}
</script>

<style scoped>
/*++++++弹窗样式表：++++++++*/
::v-deep .drawerclass .el-drawer__header {
    margin-bottom: 5px;
    font-size: 20px;
    color: #333333;
    padding-right:0px;
    margin-left: 20px;
  } 
  .drawerbody{
    width: 100%;
    padding-top:20px;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 40px;
    line-height: 20px;
  }
  .drawerintro{
    margin:0 10% 5px 10%;
    font-size: 12px;
    color:#333333;
  }

  /* Drawer 基本样式调整 */
.succeeddrawer {
  padding: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
  line-height: 1.5;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

/* 成功提示文本样式 */
.succeeddrawer div {
  margin-bottom: 20px;
  font-size: 16px;
  text-align: center;
}

/* 成功图标动画 */
@keyframes popIn {
  0% {
    transform: scale(0); /* 开始时图标缩放为0 */
    opacity: 0; /* 开始时图标完全透明 */
  }
  50% {
    transform: scale(1.2); /* 中途图标放大到比原始尺寸稍大 */
    opacity: 1; /* 中途图标完全不透明 */
  }
  100% {
    transform: scale(1); /* 结束时图标恢复到原始尺寸 */
  }
}
/* 成功图标样式 */
.succeedicon {
  display: block;
  margin: 40px auto 30px; /* 增加下边距以增大图标与文案之间的间隙 */
  width: 60px; /* 根据需要调整图标大小 */
  height: auto;
  animation: popIn 0.6s ease-out; /* 应用动画 */
}
.succeedtext{
  margin:0 8% 0 8%;
}
/* 按钮样式 */
.succeeddrawer .el-button {
  display: block;
  margin: 0 auto;
  padding: 15px 40px;
  margin:30px auto 40px auto;
  font-size: 14px;
  color: #fff;
  background-color: #409EFF;
  border-color: #409EFF;
  border-radius: 30px; /* 圆角按钮 */
  transition: background-color .3s, border-color .3s, color .3s;
}

.succeeddrawer .el-button:hover {
  background-color: #66b1ff;
  border-color: #66b1ff;
  color: #fff;
}

/* Drawer 标题样式 */
.succeeddrawer .el-drawer__header {
  font-size: 18px;
  color: #333;
  text-align: center;
  border-bottom: 1px solid #ebeef5;
}
</style>