<template>
<!-- v-if="showSuccessDrawer" -->
    <el-drawer
        :title="title"
        :visible="visible"
        direction="btt"
        custom-class="succeeddrawer"
        size= "auto"
        :withHeader="false"
        :wrapperClosable="false"
      >
        <img class="succeedicon"  src="../../assets/succeed.png"/>
        <div class="succeedtext">{{ text }}</div>
        <el-button type="primary" @click="closeSuccessDrawer" >我知道了</el-button>
    </el-drawer>
</template>

<script>
export default{
	props: ['visible', 'title', 'text'],
	data(){
			return {

			};
	},
	methods: {
		// 新增一个关闭成功提示drawer的方法
		closeSuccessDrawer() {
			this.$emit('close', false);
		},
	}
}
</script>

<style scoped>
/*++++++弹窗样式表：++++++++*/
::v-deep .drawerclass .el-drawer__header {
    margin-bottom: 5px;
    font-size: 20px;
    color: #333333;
    padding-right:0px;
    margin-left: 20px;
  } 
  .drawerbody{
    width: 100%;
    padding-top:20px;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 40px;
    line-height: 20px;
  }
  .drawerintro{
    margin:0 10% 5px 10%;
    font-size: 12px;
    color:#333333;
  }

  /* Drawer 基本样式调整 */
.succeeddrawer {
  padding: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
  line-height: 1.5;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

/* 成功提示文本样式 */
.succeeddrawer div {
  margin-bottom: 20px;
  font-size: 16px;
  text-align: center;
}

/* 成功图标动画 */
@keyframes popIn {
  0% {
    transform: scale(0); /* 开始时图标缩放为0 */
    opacity: 0; /* 开始时图标完全透明 */
  }
  50% {
    transform: scale(1.2); /* 中途图标放大到比原始尺寸稍大 */
    opacity: 1; /* 中途图标完全不透明 */
  }
  100% {
    transform: scale(1); /* 结束时图标恢复到原始尺寸 */
  }
}
/* 成功图标样式 */
.succeedicon {
  display: block;
  margin: 40px auto 30px; /* 增加下边距以增大图标与文案之间的间隙 */
  width: 60px; /* 根据需要调整图标大小 */
  height: auto;
  animation: popIn 0.6s ease-out; /* 应用动画 */
}
.succeedtext{
  margin:0 8% 0 8%;
}
/* 按钮样式 */
.succeeddrawer .el-button {
  display: block;
  margin: 0 auto;
  padding: 15px 40px;
  margin:30px auto 40px auto;
  font-size: 14px;
  color: #fff;
  background-color: #409EFF;
  border-color: #409EFF;
  border-radius: 30px; /* 圆角按钮 */
  transition: background-color .3s, border-color .3s, color .3s;
}

.succeeddrawer .el-button:hover {
  background-color: #66b1ff;
  border-color: #66b1ff;
  color: #fff;
}

/* Drawer 标题样式 */
.succeeddrawer .el-drawer__header {
  font-size: 18px;
  color: #333;
  text-align: center;
  border-bottom: 1px solid #ebeef5;
}
</style>