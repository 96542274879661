
import globaldata from "../config/globaldata"
import {getCookie, setCookie} from "../func/util"
import { sha256 } from 'js-sha256';

import axios from 'axios'

let urlbody = globaldata.IS_RELEASE ?  globaldata.API_PREFIX+globaldata.SERVER_HOST+":"+globaldata.API_PORT : globaldata.test_API_PREFIX+globaldata.test_SERVER_HOST+":"+globaldata.test_API_PORT
//登录uid
export async function postLogin(uid,uniqueid) {
    console.log("开始登录...")
    var url = urlbody+"/api/login"
    var result = null
    await axios.post(url, {
        uid: uid,
        uniqueid: uniqueid
    })
    .then(function (response) {
        //console.log(response)
        //如果登陆成功
        if(response.data.status == 200){
            var user = response.data.data
            if(typeof user.uid === "undefined"){
                console.log("登陆信息出错，返回")
                return
            }
            //打印信息
            if(user.uid == getCookie('uid')){
                console.log("已有账号登录:"+user.uid)
            }else{
                console.log("新账号登录:"+user.uid)
            }
            
            //写入新的登录信息
            setCookie('uid',user.uid)
            setCookie('uniqueid',user.uniqueid)

            result = {avatar:user.avatar,nickname:user.nickname}
        }
        
    })
    .catch(function (error) {
        console.log(error);
        /*写入新的登录信息,这里有点危险，如果因为网络不好等原因失败就容易误判
        setCookie('uid',0)
        setCookie('uniqueid',"")
        */
    });
    return result
}

//尝试加入群聊
export async function postJoinGroup(groupname) {
    var url = urlbody+"/api/joingroup"
   

    let result = null
    //判断当前登录情况
    var userinfo = getLoginInfo();
    
    if(userinfo == null){
        //如果本地uid或uniqueid没被初始化过，传入初始0值，登录请求会返回新注册uid和uniqueid
        result = {succeed:false,message:"登录信息异常，请刷新页面后重试"}
        return result;
    }

    await axios.post(url, {
        gname: groupname
    })
    .then(function (response) {
        //console.log(response)
        //如果登陆成功
        if(response.data.status == 200){
            var group = response.data.data
            //打印信息
            if(typeof group.gid === "undefined"){
                console.log("进群失败，不存在gid")
                result = {succeed:false,message:"获取群信息失败,请尝试刷新网页重试"}
                console.log("进群失败了"+result)
                return result;
            }else{
                result = {succeed:true,gid:group.gid};
                console.log("进群成功"+result)
            }
            
        }else if(response.data.status == 500){
            
            result = {succeed:false,message:response.data.msg}
            console.log("进群失败了"+result)
            return result;
        }
    })
    .catch(function (error) {
        console.log(error);
    });

    return result;
}
//查询群信息
export function postGroupInfo(gid, cb){
    var url = urlbody+"/api/getgroupinfo"
    let result = null
    axios.post(url, {
        groupId:gid
    })
    .then(function (response) {
        //console.log("aaaa",response)
        //如果登陆成功
        if(response.data.status == 200){
            var group = response.data.data
            result = {succeed:true,gid:group.gid,groupname:group.gname}
            if(typeof group.gid === "undefined"){
                result = {succeed:false,message:response.data.msg}
            }
            
        }else{
            result = {succeed:false,message:response.data.msg}
        }
        cb(result)
    })
    .catch(function (error) {
        result = {succeed:false}
        cb(result)
        console.log(error);
    });
}

//查询群信息
export function postInitGroup(gid, cb){
    var url = urlbody+"/api/initgroup"

    let result = null
    //判断当前登录情况
    var userinfo = getLoginInfo();
    if(userinfo == null){
        //如果本地uid或uniqueid没被初始化过，传入初始0值，登录请求会返回新注册uid和uniqueid
        result = {succeed:false,message:"登录信息异常，请刷新页面后重试"}
        return result;
    }
    var uid = userinfo.uid
    var uniqueid = userinfo.uniqueid

    axios.post(url, {
        gid:gid,
        uid:uid,
        uniqueid:uniqueid
    })
    .then(function (response) {
        if(response.data.status == 200){
            
            var priceinfo = response.data.data
            result = {succeed:true,price:priceinfo.price,intro:priceinfo.intro}
            if(typeof result.price === "undefined"){
                result = {succeed:false,message:response.data.msg}
            }
            
        }else{
            result = {succeed:false,message:response.data.msg}
        }
        cb(result)
    })
    .catch(function (error) {
        result = {succeed:false,message:"系统异常，请重试"}
        cb(result)
        console.log(error);
    });
}
//获取群聊天消息记录，群id，当前msgid（如果取最新的，此处填0），向前取数数量或向后取数数量，填且仅填一个
//向前取数时，获得的数组是gid降序（最新的发言为[0]）；向后取数时，获得的数组是gid升序，旧的发言在[0]
export function postChatHistory(gid, nowMsgId, preNum, nextNum,cb){
    //判断当前登录情况
    var userinfo = getLoginInfo();
    if(userinfo == null){
        //如果本地uid或uniqueid没被初始化过，传入初始0值，登录请求会返回新注册uid和uniqueid
        result = {succeed:false,message:"登录信息异常，请刷新页面后重试"}
    }
    var uid = userinfo.uid

    var url = urlbody+"/api/getchathistory"
    let result = null
    axios.post(url, {
        "groupId":gid,
        "nowMsgId":nowMsgId,
        "userId":uid,
        "preNum":preNum,
        "nextNum":nextNum,
        "protocolVer":globaldata.PROTOCOL_VERSION
    })
    .then(function (response) {
        //console.log(response)
        //如果获取列表成功
        if(response.data.status == 200){
            var chatMsgs = response.data.data
            /* chatMsgs结构
            [
                {dislikeNum:0,
                receiverId:0,
                likeNum:0,
                type:1,
                senderName:km,
                msg:koiu,
                groupId:162,
                msgId:692,
                senderAvatar:k,
                senderId:100150},
                {},{}, ...
            ]
            */

            if(chatMsgs == null || typeof chatMsgs[0] === "undefined"){
                result = {succeed:false,message:"没有更多数据了"}
            }
            else{
                var nowuserlikelist = response.data.attach
                result = {succeed:true,chatMsgs:chatMsgs,likeMids:nowuserlikelist}
            }

            cb(result)
        }
    })
    .catch(function (error) {
        result = {succeed:false}
        cb(result)
        console.log(error);
    });
}
//传入原始的list，对list进行重排
export function getGroupListStatus(rawlist,cb){
    //该方法服务端还没实现，此处先直接返回空
    let result = {succeed:false,message:"listItemStatus数据接口暂未实现"}
    cb(result)
    return
    /*
    //判断当前登录情况
    var userinfo = getLoginInfo();
    if(userinfo == null){
        //如果本地uid或uniqueid没被初始化过，传入初始0值，登录请求会返回新注册uid和uniqueid
        result = {succeed:false,message:"登录信息异常，请刷新页面后重试"}
    }
    var uid = userinfo.uid
    var uniqueid = userinfo.uniqueid

    var url = urlbody+"/api/getgroupliststatus"
    let result = null
    axios.post(url, {
        uid: uid,
        uniqueid:uniqueid,
        gnamelist:rawlist
    })
    .then(function (response) {
        //console.log(response)
        //如果获取列表成功
        if(response.data.status == 200){
            var listItemStatus= response.data.data
            
            //listItemStatus的结构 使用对象来存储状态，以list的item作为标识，如{"群名1":111,"群名2":222} 
            

            if(listItemStatus == null || typeof listItemStatus[0] === "undefined"){
                result = {succeed:false,message:"未获得listItemStatus数据"}
            }
            else{
                result = {succeed:true,listItemStatus:listItemStatus}
            }
            cb(result)
        }
    })
    .catch(function (error) {
        result = {succeed:false}
        cb(result)
        console.log(error);
    });
    */
}
//修改用户名
export async function postChangeName(newname) {
    var url = urlbody+"/api/setusername"

    let result = null
    //判断当前登录情况
    var userinfo = getLoginInfo();
    if(userinfo == null){
        //如果本地uid或uniqueid没被初始化过，传入初始0值，登录请求会返回新注册uid和uniqueid
        result = {succeed:false,message:"登录信息异常，请刷新页面后重试"}
        return result;
    }
    var uid = userinfo.uid
    var uniqueid = userinfo.uniqueid

    await axios.post(url, {
        uid: uid,
        uniqueid:uniqueid,
        name:newname
    })
    .then(function (response) {
        //console.log(response)
        //如果登陆成功
        if(response.data.status == 200){
            var user = response.data.data
            if(typeof user.uid === "undefined"){
                result = {succeed:false,message:"网络异常，请重试"}
                return result;
            }
            //更新全局信息
            globaldata.LOGIN_USER_AVATAR = user.avatar
            globaldata.LOGIN_USER_NAME = user.nickname
            result = {succeed:true,message:""}

        }
        else{
            result = {succeed:false,message:response.data.msg}
        }
    })
    .catch(function (error) {
        console.log("changename error,please try")
        console.log(error);
    });

    return result;
}

//获取热门群聊
export async function postHotGroup(cb) {
    var url = urlbody+"/api/gethotgroup"

    let result = []
    //判断当前登录情况
    var userinfo = getLoginInfo();
    if(userinfo == null){
        //如果本地uid或uniqueid没被初始化过，传入初始0值，登录请求会返回新注册uid和uniqueid
        result = {succeed:false,message:"登录信息异常，请刷新页面后重试"}
        return result;
    }
    var uid = userinfo.uid
    var uniqueid = userinfo.uniqueid

    await axios.post(url, {
        uid: uid,
        uniqueid:uniqueid
    })
    .then(function (response) {
        //console.log(response)
        //如果登陆成功
        if(response.data.status == 200){
            result = response.data.data
            console.log("获取hot群",result)
            cb(result);   
        }
    })
    .catch(function (error) {
        console.log(error);
        cb(result);
    });
}
//根据关键词获取搜索结果
export async function getSearchResult(keyword,cb) {
    var url = urlbody+"/api/groupsearch"

    let result = []
    //判断当前登录情况
    var userinfo = getLoginInfo();
    if(userinfo == null){
        //如果本地uid或uniqueid没被初始化过，传入初始0值，登录请求会返回新注册uid和uniqueid
        result = {succeed:false,message:"登录信息异常，请刷新页面后重试"}
        return result;
    }
    var uid = userinfo.uid
    var uniqueid = userinfo.uniqueid

    await axios.post(url, {
        uid: uid,
        uniqueid:uniqueid,
        keyword:keyword
    })
    .then(function (response) {
        //console.log(response)
        //如果获取成功
        if(response.data.status == 200){
            result = response.data.data
            console.log("获取searchresult",result)
            cb(result);   
        }
    })
    .catch(function (error) {
        console.log(error);
        cb("");
    });
}

//获取预约群聊
export async function getGroupSchedule(cb) {
    var url = urlbody+"/api/getgroupschedule"

    let result = []
    //判断当前登录情况
    var userinfo = getLoginInfo();
    if(userinfo == null){
        //如果本地uid或uniqueid没被初始化过，传入初始0值，登录请求会返回新注册uid和uniqueid
        result = {succeed:false,message:"登录信息异常，请刷新页面后重试"}
        return result;
    }
    var uid = userinfo.uid

    await axios.post(url, {
        uid: uid
    })
    .then(function (response) {
        //console.log(response)
        //如果获取成功
        if(response.data.status == 200){
            result = response.data.data
            console.log("获取Schedule",result)
            cb(result);   
        }
    })
    .catch(function (error) {
        console.log(error);
        cb(result);
    });
}

//加入预约群聊
export async function addGroupVote(gname,cb) {
    var url = urlbody+"/api/addgroupvote"

    let result = []
    //判断当前登录情况
    var userinfo = getLoginInfo();
    if(userinfo == null){
        //如果本地uid或uniqueid没被初始化过，传入初始0值，登录请求会返回新注册uid和uniqueid
        result = {succeed:false,message:"登录信息异常，请刷新页面后重试"}
        return result;
    }
    var uid = userinfo.uid
    var uniqueid = userinfo.uniqueid

    await axios.post(url, {
        uid: uid,
        uniqueid:uniqueid,
        gname: gname
    })
    .then(function (response) {
        //console.log(response)
        //如果获取成功
        if(response.data.status == 200){
            result = response.data.data
            console.log("addvote成功",result)
            cb(result);   
        }
    })
    .catch(function (error) {
        console.log(error);
        cb(result);
    });
}

//获取群的extendinfo
export async function postGroupSysInfo(gid,cb) {
    var url = urlbody+"/api/getsysinfo"

    let result = null
    //判断当前登录情况
    var userinfo = getLoginInfo();
    if(userinfo == null){
        //如果本地uid或uniqueid没被初始化过，传入初始0值，登录请求会返回新注册uid和uniqueid
        result = {succeed:false,message:"登录信息异常，请刷新页面后重试"}
        return result;
    }
    var uid = userinfo.uid
    var uniqueid = userinfo.uniqueid

    await axios.post(url, {
        uid: uid,
        uniqueid:uniqueid,
        gid:gid
    })
    .then(function (response) {
        //console.log(response)
        //如果登陆成功
        if(response.data.status == 200){
            var info = response.data.data
            if(info != null){
                cb({sysinfo:info.sysinfo,sysname:info.sysname})
            }
            cb(null)

        }
    })
    .catch(function (error) {
        console.log(error);
    });
}

//购买群票，在用户点击支付后立即调用生成预支付订单
export function postBuyGroupTicket(kind,wxopenid,gid,cb){
    var url = urlbody+"/api/buygroupticket"

    let result = null
    //判断当前登录情况
    var userinfo = getLoginInfo();
    if(userinfo == null){
        //如果本地uid或uniqueid没被初始化过，传入初始0值，登录请求会返回新注册uid和uniqueid
        result = {succeed:false,message:"登录信息异常，请刷新页面后重试"}
        return result;
    }
    var uid = userinfo.uid
    var uniqueid = userinfo.uniqueid
    //计算token
    var tempstr = "cikepayinfo" + kind + uid + uniqueid + gid;
    var token = sha256(tempstr).toUpperCase();

    //获取当前是在h5还是web
    //1，在微信内，2，在普通H5内，3，在pc页
    var platform = 0 //其他
    if(isWeChat()){
        platform = 1 //微信内，直接打开新页面
    }else if(isMobile()){
        platform = 2 //普通H5，直接打开新页面
    }else if(isPC()){
        platform = 3 //pc页,直接立即生成订单二维码供微信扫码
    }

    axios.post(url, {
        kind:kind,
        gid:gid,
        uid:uid,
        platform:platform,
        wxopenid:wxopenid,
        token:token
    })
    .then(function (response) {
        console.log("kind:",kind,"|gid:",gid,"|uid:",uid,"|token:",token)
        console.log("buygroupticket",response)
        if(response.data.status == 200){
            var paydata = response.data.data
            if(kind == "testpay"){
                result = {succeed:true,platform:platform,payresult:paydata}
                //payresult:{price: 500, intro: 'xxxx',payid:'xxxx', redirect_url: 'xxxx'}
            }
            else if(kind == "ysm"){
                result = {succeed:true,platform:platform,payresult:paydata}
            }
            else if(kind == "wechat"){
                result = {succeed:true,platform:platform,payresult:paydata}
            }else{
                result = {succeed:false,platform:platform,message:"支付方式错误"}
            }
        }else{
            result = {succeed:false,platform:platform,message:response.data.msg}
        }
        cb(result)
    })
    .catch(function (error) {
        result = {succeed:false,message:"系统异常，请重试"}
        console.log("postBuyGroupTicket error:",error);
        cb(result)
    });
}

//查询支付状态，在支付后根据payid轮询
export function postGroupPaidStatus(payid,cb){
    var url = urlbody+"/api/grouppaystatus"
    console.log("paidstatus payid:",payid)

    let result = null
    if(payid == null){
        result = {succeed:false,message:"参数错误！"}
    }

    axios.post(url, {
        payid:payid
    })
    .then(function (response) {
        console.log("grouppaystatus",response)
        if(response.data.status == 200){
            var str = response.data.data
            if(str == "succeed"){
                result = {succeed:true}
            }
            else{
                result = {succeed:false,message:"等待支付中"}
            }
        }else{
            result = {succeed:false,message:"服务器错误，请重试"}
        }
        cb(result)
    })
    .catch(function (error) {
        result = {succeed:false,message:"系统异常，请重试"}
        cb(result)
        console.log(error);
    });
}

//根据输入的支付code，恢复用户的群支付
export function postRecoverGroupPay(code,gid,cb){
    var url = urlbody+"/api/recovergrouppay"

    let result = null
    if(code == null){
        result = {succeed:false,message:"参数错误！"}
    }
    //判断当前登录情况
    var userinfo = getLoginInfo();
    if(userinfo == null){
        //如果本地uid或uniqueid没被初始化过，传入初始0值，登录请求会返回新注册uid和uniqueid
        result = {succeed:false,message:"登录信息异常，请刷新页面后重试"}
        return result;
    }
    var uid = userinfo.uid
    var uniqueid = userinfo.uniqueid

    axios.post(url, {
        uid:uid,
        uniqueid:uniqueid,
        code:code,
        gid:gid
    })
    .then(function (response) {
        console.log("grouppayrecover",response)
        if(response.data.status == 200){
            result = {succeed:true,payid:response.data.data.payid}
        }else{
            result = {succeed:false,message:response.data.msg}
        }
        cb(result)
    })
    .catch(function (error) {
        result = {succeed:false,message:"系统异常，请重试"}
        cb(result)
        console.log(error);
    });
}

//获取用户的微信openid
export function postGetWxOpenid(code,cb){
    var url = urlbody+"/api/getwxopenid"

    let result = null
    if(code == null){
        result = {succeed:false,message:"参数错误！"}
    }
    //判断当前登录情况
    var userinfo = getLoginInfo();
    if(userinfo == null){
        //如果本地uid或uniqueid没被初始化过，传入初始0值，登录请求会返回新注册uid和uniqueid
        result = {succeed:false,message:"登录信息异常，请刷新页面后重试"}
        return result;
    }
    var uid = userinfo.uid
    var uniqueid = userinfo.uniqueid

    axios.post(url, {
        uid:uid,
        uniqueid:uniqueid,
        code:code
    })
    .then(function (response) {
        console.log("getWxOpenid:",response)
        if(response.data.status == 200){
            result = {succeed:true,openid:response.data.data.openid}
        }else{
            result = {succeed:false,message:response.data.msg}
        }
        cb(result)
    })
    .catch(function (error) {
        result = {succeed:false,message:"系统异常，请重试"}
        cb(result)
        console.log(error);
    });
}

//获取微信公众平台的jssdk的签名：https://developers.weixin.qq.com/doc/offiaccount/OA_Web_Apps/JS-SDK.html#%E9%99%84%E5%BD%951-JS-SDK%E4%BD%BF%E7%94%A8%E6%9D%83%E9%99%90%E7%AD%BE%E5%90%8D%E7%AE%97%E6%B3%95
export function postGetWxSdkSign(noncestr,timestamp,nowurl,cb){
    var url = urlbody+"/api/getwxsdksign"

    let result = null
    if(nowurl == null){
        result = {succeed:false,message:"参数错误！"}
    }
    //判断当前登录情况
    var userinfo = getLoginInfo();
    if(userinfo == null){
        //如果本地uid或uniqueid没被初始化过，传入初始0值，登录请求会返回新注册uid和uniqueid
        result = {succeed:false,message:"登录信息异常，请刷新页面后重试"}
        return result;
    }
    var uid = userinfo.uid
    var uniqueid = userinfo.uniqueid

    axios.post(url, {
        uid:uid,
        uniqueid:uniqueid,
        noncestr:noncestr,
        timestamp:timestamp,
        url:nowurl
    })
    .then(function (response) {
        console.log("getwxsdksign:",response)
        if(response.data.status == 200){
            result = {succeed:true,signature:response.data.data.signature}
        }else{
            result = {succeed:false,message:response.data.msg}
        }
        cb(result)
    })
    .catch(function (error) {
        result = {succeed:false,message:"系统异常，请重试"}
        cb(result)
        console.log(error);
    });
}

function getLoginInfo(){
     //判断当前登录情况
     var uid = getCookie('uid');
     var uniqueid = getCookie('uniqueid');
     if(uid == null || uniqueid == null){
         return null
     }
     else{
        return {uid:uid,uniqueid:uniqueid}
     }
}

// 判断是否为移动端
function isMobile() {
    const ua = navigator.userAgent;
    const isMobile = /AppleWebKit.*Mobile/i.test(ua) || /Android|Windows Phone|BlackBerry/i.test(ua);
    return isMobile;
  }
  
  // 判断是否在微信中打开
  function isWeChat() {
    const ua = navigator.userAgent.toLowerCase();
    const isWeChat = /micromessenger/.test(ua);
    return isWeChat;
  }
  
  // 判断是否在PC端打开
  function isPC() {
    const ua = navigator.userAgent;
    const agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
    let flag = true;
    for (let v = 0; v < agents.length; v++) {
      if (ua.indexOf(agents[v]) > 0) {
        flag = false;
        break;
      }
    }
    return flag;
  }