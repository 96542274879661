export default {
    //用户信息
    /*
    "LOGIN_USER_UNIQUEID":"",
    "LOGIN_USER_NAME":"",
    "LOGIN_USER_UID":0,
    */
    //协议版本号
    "PROTOCOL_VERSION":"2",

    //微信APPID
    "WXAPPID":"wxb4597ef7da9a6bf0",

    //是否为正式环境
    "IS_RELEASE":true,

    //网络信息，本地环境
    "test_API_PREFIX":"http://",
    "test_SERVER_HOST":"192.168.0.109",//"192.168.0.115"  // "localhost"
    "test_API_PORT":"9000",
    "test_SOCKET_PREFIX":"ws://",
    "test_SOCKET_PORT":"8899",
    "test_ALIYUN_IMG_DIR":"chat-test",
    //网络信息,正式环境
    "API_PREFIX":"https://",
    "SERVER_HOST":"www.cikeshikong.com",
    "API_PORT":"8000",
    "SOCKET_PREFIX":"wss://",
    "SOCKET_PORT":"8899",
    "ALIYUN_IMG_DIR":"chat",

    //socket消息的类型枚举
    "MSG_TYPE_SYS":0, //系统消息
    "MSG_TYPE_TEXT":1, //文字消息
    "MSG_TYPE_IMAGE":2 ,//图片消息
    
    //消息动作枚举，目前只有connect、chat、likemsg3中
    "MSG_ACTION_CONNECT":1,//连接动作，有用户上线
    "MSG_ACTION_CHAT": 2,//发送消息动作
    "MSG_ACTION_LIKEMSG":5 //与用户发起点赞



}