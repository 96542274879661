<template>
    <el-drawer
        title="设置付费群"
        :visible="visible"
        direction="btt"
        custom-class="drawerclass"
        size= "auto"
        ref="drawer"
        @close="handleEditGroupClose" 
        >
        <div class="drawerintro">设置群聊信息</div>
        <div class="drawerbody">
          
          <el-form :model="editgroupform">
            <el-form-item label="群名" :label-width="formLabelWidth" >
              <el-input v-model="editgroupform.gname" type="text" maxlength="10" show-word-limit autocomplete="off" placeholder="限4-10个中文、字母或数字" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="付费价格" :inline="true" :label-width="formLabelWidth" >
              <el-input v-model="editgroupform.price" type="number" autocomplete="off" placeholder="如：2.99"><template slot="append">元</template></el-input >
            </el-form-item>
            <el-form-item label="群聊简介" :label-width="formLabelWidth">
              <el-input v-model="editgroupform.intro" type="text" autocomplete="off" clearable placeholder="选填，不超过20个字" maxlength="20" show-word-limit ></el-input>
            </el-form-item>
          </el-form>
          <div class="demo-drawer__footer">
            <el-button @click="cancelForm">取 消</el-button>
            <el-button type="primary" @click="handleeditgroup" :loading="loading">{{ loading ? '提交中 ...' : '修改群信息' }}</el-button>
          </div>
        </div>
      </el-drawer>
</template>

<script>
import {postSetPaidGroup} from "@/func/creatorHandler";

export default{
  props: ['visible', 'formData'],
  data(){
      return {
        editgroupform:{
          gname: '',
          price: '',
          intro: '',
        },
        formLabelWidth: '20%',
        loading: false,
      };
  },
  watch: {
    formData: {
      handler(newVal){
        this.editgroupform = {...newVal}
      },
      deep: true
    }
  },
  methods: {
    handleEditGroupClose(){
      //this.getGroupList()
      this.$emit('close', false);
    },
    cancelForm(){
      this.$emit("cancelForm", 'editgroupdialog')
    },
    handleeditgroup(){
      this.loading = true; // 开始加载
      const gname = this.editgroupform.gname;
      const intro = this.editgroupform.intro;
      const pricestr = this.editgroupform.price;
      // 使用正则表达式检查价格是否是一个2位小数以内的数
      let price = 0;
      const regex = /^\d+(\.\d{1,2})?$/;
      if (regex.test(pricestr)) {
        // 如果是2位小数以内的数，则乘以100变成整数
        price = parseFloat(pricestr) * 100;
      } else {
        // 如果小数位数大于2位，则报错
        this.$message.error('价格小数位数不能超过2位');
        this.loading = false; // 结束加载
        return
      }
      console.log("gname,",gname)
      console.log("intro,",intro)
      console.log("price,",price)
      postSetPaidGroup(gname,intro,price, (result) => {
        this.loading = false; // 结束加载
        if (result.succeed) {
          //申请成功
          //关闭现有的申请drawer
          this.$emit('close', false);
          this.$emit('updateList'); // 触发更新列表的事件
          // 申请成功
          this.$message.success('群聊信息更新成功！');
        } else {
          // 登录失败
          this.$message.error(result.message || '系统错误，请重试');
        }
      });
    },
  }
}
</script>

<style scoped>
/*++++++弹窗样式表：++++++++*/
::v-deep .drawerclass .el-drawer__header {
    margin-bottom: 5px;
    font-size: 20px;
    color: #333333;
    padding-right:0px;
    margin-left: 20px;
  } 
  .drawerbody{
    width: 100%;
    padding-top:20px;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 40px;
    line-height: 20px;
  }
  .drawerintro{
    margin:0 10% 5px 10%;
    font-size: 12px;
    color:#333333;
  }

  /* Drawer 基本样式调整 */
.succeeddrawer {
  padding: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
  line-height: 1.5;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

/* 成功提示文本样式 */
.succeeddrawer div {
  margin-bottom: 20px;
  font-size: 16px;
  text-align: center;
}

/* 成功图标动画 */
@keyframes popIn {
  0% {
    transform: scale(0); /* 开始时图标缩放为0 */
    opacity: 0; /* 开始时图标完全透明 */
  }
  50% {
    transform: scale(1.2); /* 中途图标放大到比原始尺寸稍大 */
    opacity: 1; /* 中途图标完全不透明 */
  }
  100% {
    transform: scale(1); /* 结束时图标恢复到原始尺寸 */
  }
}
/* 成功图标样式 */
.succeedicon {
  display: block;
  margin: 40px auto 30px; /* 增加下边距以增大图标与文案之间的间隙 */
  width: 60px; /* 根据需要调整图标大小 */
  height: auto;
  animation: popIn 0.6s ease-out; /* 应用动画 */
}
.succeedtext{
  margin:0 8% 0 8%;
}
/* 按钮样式 */
.succeeddrawer .el-button {
  display: block;
  margin: 0 auto;
  padding: 15px 40px;
  margin:30px auto 40px auto;
  font-size: 14px;
  color: #fff;
  background-color: #409EFF;
  border-color: #409EFF;
  border-radius: 30px; /* 圆角按钮 */
  transition: background-color .3s, border-color .3s, color .3s;
}

.succeeddrawer .el-button:hover {
  background-color: #66b1ff;
  border-color: #66b1ff;
  color: #fff;
}

/* Drawer 标题样式 */
.succeeddrawer .el-drawer__header {
  font-size: 18px;
  color: #333;
  text-align: center;
  border-bottom: 1px solid #ebeef5;
}
</style>