<template>
  <div id="app">
    <!--<img alt="Vue logo" src="./assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
    
    <router-view></router-view>
  </div>
  
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'
//import HomeComp from './view/HomeComp.vue';

export default {
  name: 'App',
  components: {
    //HelloWorld,
    //HomeComp
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  /*position: fixed;*/
  width: 100vw;
}

html,body, #app{height:100%;}
body{
  overflow: hidden;
}
</style>
