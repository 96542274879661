import Vue from 'vue'
import VueRouter from "vue-router"
// import HomePage from "../view/HomePage"
// import ChatPage from "../view/ChatPage"
import DownloadPage from '../view/DownloadPage';
import MainPage from '../view/MainPage';
import CreatePaidGroup from '../view/CreatePaidGroup';
import GroupActivation from '../view/GroupActivation';

Vue.use(VueRouter);

const routes =  [
    {
        path: '/',
        name: 'home',
        component: MainPage
    },
    {
        path: '/download',
        component: DownloadPage
    },
    {
        path:'/creator',
        component: CreatePaidGroup
    },
    {
        path:'/apply/crt',
        component: GroupActivation
    },
    {
        path: '/:id',
        name: 'chatRoom',
        //component: ()=>import("../view/HomePage"),
        component: MainPage
    },
];
/*
const router = new VueRouter({
    routes,
});
*/
const router = new VueRouter({
    mode: 'history',
    routes: routes
})

export default router;