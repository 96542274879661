
import globaldata from "../config/globaldata"
import {getCookie, setCookie,delCookie} from "./util"

import axios from 'axios'

let urlbody = globaldata.IS_RELEASE ?  globaldata.API_PREFIX+globaldata.SERVER_HOST+":"+globaldata.API_PORT : globaldata.test_API_PREFIX+globaldata.test_SERVER_HOST+":"+globaldata.test_API_PORT

//输入邮箱和群名登录
export function postCreatorEmailLogin(email,gname,cb){
    let url = urlbody+"/crt/emaillogin"
    let result = null
    if(!email || !gname){
        result = {succeed:false,message:"参数错误！"}
    }
    if(result != null){
        cb(result)
        return
    }
    axios.post(url, {
        email:email,
        gname:gname,
    })
    .then(function (response) {
        console.log("postCreatorEmailLogin:",response)
        if(response.data.status == 200){
            //写入新的登录信息
            setCookie('emaillogintoken',response.data.data.token)
            setCookie('emailloginstring',email)
            result = {succeed:true,token:response.data.data.token}
        }else{
            result = {succeed:false,message:response.data.msg}
        }
        cb(result)
    })
    .catch(function (error) {
        result = {succeed:false,message:"系统异常，请重试"}
        cb(result)
        console.log(error);
    });
}
//申请创建付费群
export function postCreatePaidGroup(email,gname,intro,price,cb){
    let url = urlbody+"/crt/createpaidgroup"
    let result = null
    if(!email || !gname || price<0){
        console.log(email,":",gname,":",price)
        result = {succeed:false,message:"参数错误！"}
    }
    //判断当前登录情况，暂时不需要判断
    let userinfo = getLoginInfo();
    let uid = ""
    let uniqueid = ""
    if(userinfo != null){
        //如果本地已有登录信息，传入登录信息
        uid = userinfo.uid
        uniqueid = userinfo.uniqueid
    }
    if(result != null){
        cb(result)
        return
    }
    axios.post(url, {
        uid:uid,
        uniqueid:uniqueid,
        gname:gname,
        email:email,
        intro:intro,
        price:price
    })
    .then(function (response) {
        console.log("postCreatePaidGroup:",response)
        if(response.data.status == 200){
            result = {succeed:true}
        }else{
            result = {succeed:false,message:response.data.msg}
        }
        cb(result)
    })
    .catch(function (error) {
        result = {succeed:false,message:"系统异常，请重试"}
        cb(result)
        console.log(error);
    });
}
//用户点击邮箱链接后确认创建群
export function postConfirmCreate(id,cofirmtoken,cb){
    let url = urlbody+"/crt/comfirmcreate"
    let result = null
    if(id == null || !cofirmtoken){
        result = {succeed:false,message:"参数错误！"}
    }
    if(result != null){
        cb(result)
        return
    }
    axios.post(url, {
        id:id,
        token:cofirmtoken,
    })
    .then(function (response) {
        console.log("postConfirmCreate:",response)
        if(response.data.status == 200){
            result = {succeed:true,gid:response.data.data.gid,gname:response.data.data.gname}
        }else{
            result = {succeed:false,message:response.data.msg}
        }
        cb(result)
    })
    .catch(function (error) {
        result = {succeed:false,message:"系统异常，请重试"}
        cb(result)
        console.log(error);
    });
}
//用户设置付费群聊价格
export function postSetPaidGroup(gname,intro,price,cb){
    let email = getLoginEmail()
    let url = urlbody+"/crt/setpaidgroup"
    let token = getEmailToken()
    let result = null
    if(!email || !gname || price<0){
        console.log(email,":",gname,":",price)
        result = {succeed:false,message:"参数错误！"}
    }
    if(result != null){
        cb(result)
        return
    }
    axios.post(url, {
        email:email,
        gname:gname,
        intro:intro,
        price:price,
        token:token
    })
    .then(function (response) {
        console.log("postSetPaidGroup:",response)
        if(response.data.status == 200){
            result = {succeed:true}
        }else{
            result = {succeed:false,message:response.data.msg}
        }
        cb(result)
    })
    .catch(function (error) {
        result = {succeed:false,message:"系统异常，请重试"}
        cb(result)
        console.log(error);
    });
}
//申请兑换奖品
export function postExchangeGift(gift,cashcents,receivingAccount,cb){
    let email = getLoginEmail()
    let url = urlbody+"/crt/exchangegift"
    let token = getEmailToken()
    let result = null
    let giftvalid = getGiftValueByName(gift)
    if(!email ||!token || !giftvalid){
        result = {succeed:false,message:"登录状态有误，请重新登录"}
    }
    if(gift == "CASH"){
        //目前先不处理
        result = {succeed:false,message:"平台当前现金兑换额度已满，请兑换其他奖品哦~"}
        cb(result)
        return
        /*
        if( !cashcents){
            result = {succeed:false,message:"请填写需要兑换的金额"}
        }
        if(!receivingAccount){
            result = {succeed:false,message:"请填写收款账号"}
        }*/

    }
    
    if(!email || !gift){
        result = {succeed:false,message:"信息填写有误，请检查重试"}
    }
    if(result != null){
        cb(result)
        return
    }
    axios.post(url, {
        email:email,
        token:token,
        gift:gift,
        cashcents:cashcents,
        receivingAccount:receivingAccount
    })
    .then(function (response) {
        console.log("postCreatorEmailLogin:",response)
        if(response.data.status == 200){
            result = {succeed:true,message:response.data.data}
        }else{
            result = {succeed:false,message:response.data.msg}
        }
        cb(result)
    })
    .catch(function (error) {
        result = {succeed:false,message:"系统异常，请重试"}
        cb(result)
        console.log(error);
    });
}
//用户获取所有首页的3个数据：points，invitedcount，groupcount
export function postGetHomeData(cb){
    let email = getLoginEmail()
    let url = urlbody+"/crt/gettotalpoints"
    let token = getEmailToken()
    let result = null
    if(!email || !token){
        result = {succeed:false,message:"登录信息有误，请重新登录"}
    }
    if(result != null){
        cb(result)
        return
    }
    axios.post(url, {
        email:email,
        token:token
    })
    .then(function (response) {
        //console.log("postGetHomeData:",response)
        if(response.data.status == 200){
            let alldata = response.data.data
            result = {succeed:true,points:alldata.points,invitedcount:alldata.invitedcount,groupcount:alldata.groupcount}
        }else{
            result = {succeed:false,message:response.data.msg}
        }
        cb(result)
    })
    .catch(function (error) {
        result = {succeed:false,message:"系统异常，请重试"}
        cb(result)
        console.log(error);
    });
}
//用户获取所有已创建群聊的列表。群聊创建时限制了单email最多100个上限，不用担心量太大
export function postGetAllGroupList(cb){
    let email = getLoginEmail()
    let url = urlbody+"/crt/getallgroup"
    let token = getEmailToken()
    let result = null
    if(!email || !token){
        result = {succeed:false,message:"登录信息有误，请重新登录"}
    }
    if(result != null){
        cb(result)
        return
    }
    axios.post(url, {
        email:email,
        token:token
    })
    .then(function (response) {
        //console.log("postGetAllGroupList:",response)
        if(response.data.status == 200){
            result = {succeed:true,grouplist:response.data.data}
        }else{
            result = {succeed:false,message:response.data.msg}
        }
        cb(result)
    })
    .catch(function (error) {
        result = {succeed:false,message:"系统异常，请重试"}
        cb(result)
        console.log(error);
    });
}
//用户获取所有积分记录表
export function postGetAllPointsList(nowrid,cb){
    let email = getLoginEmail()
    let url = urlbody+"/crt/getpointslist"
    let token = getEmailToken()
    let result = null
    if(!email || !token){
        result = {succeed:false,message:"登录信息有误，请重新登录"}
    }
    if(result != null){
        cb(result)
        return
    }
    axios.post(url, {
        email:email,
        token:token,
        nowrid:nowrid
    })
    .then(function (response) {
        //console.log("postGetAllPointsList:",response)
        if(response.data.status == 200){
            if(!response.data.data || response.data.data.length === 0){
                //如果没有数据了，返回nodata；
                result = {succeed:true,message:"",nodata:true}
                console.log("postGetAllPointsList nodata!")
            }
            else{
                result = {succeed:true,pointslist:response.data.data}
            }
            
        }else{
            result = {succeed:false,message:response.data.msg}
        }
        cb(result)
    })
    .catch(function (error) {
        result = {succeed:false,message:"系统异常，请重试"}
        cb(result)
        console.log("postGetAllPointsList异常：",error);
    });
}
//用户获取所有礼物兑换记录表
export function postGetAllExchangeList(nowrid,cb){
    let email = getLoginEmail()
    let url = urlbody+"/crt/getexchangelist"
    let token = getEmailToken()
    let result = null
    if(!email || !token){
        result = {succeed:false,message:"登录信息有误，请重新登录"}
    }
    if(result != null){
        cb(result)
        return
    }
    axios.post(url, {
        email:email,
        token:token,
        nowrid:nowrid
    })
    .then(function (response) {
        //console.log("postGetAllExchangeList:",response)
        if(response.data.status == 200){
            if(!response.data.data || response.data.data.length === 0){
                //如果没有数据了，返回nodata；
                result = {succeed:true,message:"",nodata:true}
                console.log("postGetAllExchangeList nodata!")
            }
            else{
                result = {succeed:true,exchangelist:response.data.data}
            }
        }else{
            result = {succeed:false,message:response.data.msg}
        }
        cb(result)
    })
    .catch(function (error) {
        result = {succeed:false,message:"系统异常，请重试"}
        cb(result)
        console.log(error);
    });
}
export function getLoginEmail(){
    let emailstr = getCookie("emailloginstring");
    if(!emailstr){
        return null;
    }
    return emailstr;
}
export function logout(){
    delCookie("emailloginstring");
}
/////////////////以下为私有函数//////////////////
//获取登录信息
function getLoginInfo(){
     //判断当前登录情况
     let uid = getCookie('uid');
     let uniqueid = getCookie('uniqueid');
     if(uid == null || uniqueid == null){
         return null
     }
     else{
        return {uid:uid,uniqueid:uniqueid}
     }
}

function getEmailToken(){
    let emailtoken = getCookie("emaillogintoken");
    if(!emailtoken){
        return null;
    }
    return emailtoken;
}


//获取礼物价值，单位为分
function getGiftValueByName(giftname){
     // 定义一个对象来映射礼物名和数值
    const giftMap = {
        "10QB": 1000,
        "100JD": 10000,
        "500JD": 50000,
        "CASH": -1
    };
  // 使用礼物名从映射中获取对应的数值
  // 如果礼物名不存在于映射中，则返回undefined
  return giftMap[giftname];
}
