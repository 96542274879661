//获取cookie、
export function getCookie(name) {
    var reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    var arr = document.cookie.match(reg)
    if (arr)
    {
      return (decodeURIComponent(arr[2]));
    }
    else
      return null;
}

  
//设置cookie,增加到vue实例方便全局调用
export function setCookie (c_name, value, expiredays) {
  var days = 30
  if(expiredays != null){
    days = expiredays
  }
  var exdate = new Date();
  exdate.setDate(exdate.getDate() + days);
  //value需要编码，以适配safari没法储存中文的问题：
  var cookieStr = encodeURIComponent(decodeURI(value))
  document.cookie = c_name + "=" + cookieStr + ((days == null) ? "" : ";expires=" + exdate.toGMTString());
}

//删除cookie
export function delCookie (name) {
  var exp = new Date();
  exp.setTime(exp.getTime() - 1);
  // Set cookie value to empty string and expiration date to a past time
  document.cookie = name + "=;expires=" + exp.toGMTString() + ";path=/";
}

//timestamp毫秒时间戳转日期格式
export function timestampToTime(timestamp) {
  var date = new Date(timestamp);//时间戳需为毫秒
  var Y = date.getFullYear() + '-';
  var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
  var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
  var h = (date.getHours()<10 ? "0" + date.getHours() : date.getHours()) + ':';
  var m = (date.getMinutes()<10 ? "0" + date.getMinutes() : date.getMinutes()) + ':';
  var s = date.getSeconds()<10 ? "0" + date.getSeconds() : date.getSeconds();
  return Y+M+D+h+m+s;
}

// 将timestamp转换为日期字符串
export function formatDate(timestamp) {
  const date = new Date(timestamp);
  const today = new Date();
  const hours = date.getHours();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  const diff = Math.floor((date - today) / (1000 * 60 * 60 * 24));
  let result = '';
  if (diff === 0 && hours < 18) {
    result = '今天';
  }else if(diff === 0 && hours > 17){
    result = '今晚';
  } 
  else if (diff === 1 && hours < 18) {
    result = '明天';
  }else if(diff === 1 && hours > 17){
    result = '明晚';
  }
   else {
    result = `${diff}天后`;
  }
  if(diff <= 1){
    return result;
  }
  let m = date.getMonth() + 1
  return `${m<10 ? '0' + m : m}月${date.getDate()<10 ? '0'+ date.getDate(): date.getDate()}日`;
}

// 将timestamp转换为时间字符串
export function formatTime(timestamp) {
  const date = new Date(timestamp);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;
}

const splitChar = "_&_";

export function getHistoryGroup(){
  let temp = getCookie('HistoryGroup');
  let list = temp ? temp.split(splitChar) : [] ;
  return list
}

export function addHistoryGroup(gname){
  let maxnum = 100 //最大历史群聊最多100个
  let str = getCookie('HistoryGroup')
  let temp = []
  
  
  if(str != null && str != ""){
      temp = str.split(splitChar);
  }
  //标识是否需要新增
  let ifnew = true
  for(var i = 0 ;i<temp.length;i++){
      if(temp[i] == gname){
          //如果已经有相同的记录了，将这个记录提到第一位，然后直接return
          temp.splice(i,1)
          temp.unshift(gname)
          ifnew = false
      }
  }
  if(ifnew){
      //仅在访问了新的历史群时才新增
      temp.unshift(gname)
      //如果新增后长度过长，需要裁断长度
      if(temp.length > maxnum){
          //temp.splice(temp.length-1,(maxnum-temp.length))
          temp.length = maxnum
      }
  }
  //记录最终的cookie，并更新显示值
  setCookie('HistoryGroup',temp.join(splitChar))
}
export function  deleteHistoryGroup(gname){
  let str = getCookie('HistoryGroup')
  let temp = []
  if(str != null && str != ""){
      temp = str.split(splitChar);
  }
  for(var i = 0 ;i<temp.length;i++){
      if(temp[i] == gname){
          //找到相同的记录了，直接移除
          temp.splice(i,1)
          break
      }
  }
  //记录最终的cookie，并更新显示值
  setCookie('HistoryGroup',temp.join(splitChar))
}

/*
页面调用使用：
调用方法：
设置：this.$cookieStore.setCookie('cookieName','cookieValue');
获取：var temp = this.$cookieStore.getCookie('cookieName');
删除：this.$cookieStore.delCookie('cookieName');
*/