<template>
    <el-drawer
        title="兑换记录"
        :visible="visible"
        direction="btt"
        custom-class="drawerclass"
        size= "auto"
        ref="drawer"
        @close = "handleCloseList"
        >
        <div class="drawerintro">仅保留近30天兑换记录</div>
        <div class="drawerbody">
          <el-table :data="exchangelist" height="250"
          v-el-table-infinite-scroll="handleExchangeScroll"
          :infinite-scroll-distance="50"
          :infinite-scroll-disabled="disabled"
          >
            <el-table-column property="time" label="申请日期" align="center" >
              <template slot-scope="scope">
                  {{ formatTime(scope.row.time) }}
              </template>
            </el-table-column>
            <el-table-column property="item" label="兑换项目" align="center"></el-table-column>
            <el-table-column property="status" label="兑换情况" align="center"></el-table-column>
          </el-table>
        </div>
    </el-drawer>
</template>

<script>
import {postGetAllExchangeList} from "@/func/creatorHandler";
export default{
    props: ['visible'],
    watch: {
        visible(newVal) {
            if (newVal) {
                console.log("exchangeListDrawer is now visible");
                this.getExchangeList();
            }
            else{
                console.log("关闭exchangeListDrawer，lastExchangeRid:",this.lastExchangeRid)
                this.lastExchangeRid = 0
                this.exchangelist = []
                this.loading = false
                this.nomoreflag = false
            }
        }
    },
    computed: {
      disabled () {
        return this.loading || this.nomoreflag
      }
    },
    data(){
        return {
          loading: false,
          nomoreflag:false,
          //兑换详情弹窗数据结构
          lastExchangeRid:0,
          exchangelist:[],
        };
    },
    methods: {
      handleCloseList(){
        this.$emit('close');
      },
      getExchangeList(){
        var that = this
        this.loading = true
        console.log("请求的lastExchangeRid:",this.lastExchangeRid)
        postGetAllExchangeList(this.lastExchangeRid,(result) => {
          that.loading =false
          if (result.succeed) {
            //如果是空数据
            if(result.nodata){
              console.log("空数组！")
              that.nomoreflag = true
            }
            else{
              // 获取成功
              that.exchangelist.push(...result.exchangelist)
              //console.log("lastExchangeRid:",that.lastExchangeRid)
              //更新当前最后一条记录的rid，为后续加载提供更多数据
              that.lastExchangeRid = that.exchangelist[that.exchangelist.length - 1].rid
              console.log("new lastExchangeRid:",that.lastExchangeRid)
            }
            
          } else {
            // 获取失败
            that.$message.error(result.message || '获取群列表失败，请重试');
          }
        });
      },
      handleExchangeScroll(){
          // 确保当前不在加载状态且列表可见
          if(this.loading || !this.visible){
              //如果当前列表不可见，不处理。此处容错的，不知道为什么列表隐藏后会触发scroll，很奇怪
              return;
          }
          console.log("exchangescroll!!")
          //请求list
          this.getExchangeList()
      },
      formatTime(timestamp) {
        const date = new Date(timestamp);
        const month = ('0' + (date.getMonth() + 1)).slice(-2); // 补零处理
        const day = ('0' + date.getDate()).slice(-2); // 补零处理
        const hours = ('0' + date.getHours()).slice(-2); // 补零处理
        const minutes = ('0' + date.getMinutes()).slice(-2); // 补零处理
        const seconds = ('0' + date.getSeconds()).slice(-2); // 补零处理

        return `${month}/${day} ${hours}:${minutes}:${seconds}`;
      }

        
    }
}
</script>

<style scoped>
/*++++++弹窗样式表：++++++++*/
::v-deep .drawerclass .el-drawer__header{
    margin-bottom: 5px;
    font-size: 20px;
    color: #333333;
    padding-right:0px;
    margin-left: 20px;
  } 
  .drawerbody{
    width: 100%;
    padding-top:20px;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 40px;
    line-height: 20px;
  }
  .drawerintro{
    margin:0 10% 5px 10%;
    font-size: 12px;
    color:#333333;
  }

  /* Drawer 基本样式调整 */
.succeeddrawer {
  padding: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
  line-height: 1.5;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

/* 成功提示文本样式 */
.succeeddrawer div {
  margin-bottom: 20px;
  font-size: 16px;
  text-align: center;
}

/* 成功图标动画 */
@keyframes popIn {
  0% {
    transform: scale(0); /* 开始时图标缩放为0 */
    opacity: 0; /* 开始时图标完全透明 */
  }
  50% {
    transform: scale(1.2); /* 中途图标放大到比原始尺寸稍大 */
    opacity: 1; /* 中途图标完全不透明 */
  }
  100% {
    transform: scale(1); /* 结束时图标恢复到原始尺寸 */
  }
}
/* 成功图标样式 */
.succeedicon {
  display: block;
  margin: 40px auto 30px; /* 增加下边距以增大图标与文案之间的间隙 */
  width: 60px; /* 根据需要调整图标大小 */
  height: auto;
  animation: popIn 0.6s ease-out; /* 应用动画 */
}
.succeedtext{
  margin:0 8% 0 8%;
}
/* 按钮样式 */
.succeeddrawer .el-button {
  display: block;
  margin: 0 auto;
  padding: 15px 40px;
  margin:30px auto 40px auto;
  font-size: 14px;
  color: #fff;
  background-color: #409EFF;
  border-color: #409EFF;
  border-radius: 30px; /* 圆角按钮 */
  transition: background-color .3s, border-color .3s, color .3s;
}

.succeeddrawer .el-button:hover {
  background-color: #66b1ff;
  border-color: #66b1ff;
  color: #fff;
}

/* Drawer 标题样式 */
.succeeddrawer .el-drawer__header {
  font-size: 18px;
  color: #333;
  text-align: center;
  border-bottom: 1px solid #ebeef5;
}
</style>