<template>
  <div class="container">
    <div v-if="showSuccess" class="success-message">
      <img class="succeedicon"  src="../assets/succeed.png"/>
      <div class="gname">{{ gname }}</div>
      <div>群聊已激活！将在 {{seconds}} 秒后自动跳转至群聊</div>
      <el-button class = "btn" @click="redirectToGroupChat">立即跳转</el-button>
    </div>
    <div v-if="showError" class="error-message">
      <img class="failedicon"  src="../assets/failed.png"/>
      <div>激活失败！</div>
      <div>{{errorMessage}}</div>
  </div>
  </div>
</template>

<script>
import { postConfirmCreate } from "../func/creatorHandler";

export default {
  data() {
    return {
      id: null,
      token: null,
      showSuccess: false,
      showError: false,
      errorMessage:"群聊激活失败，请刷新页面重试。如重试多次仍不成功，请尝试重新申请群聊",
      seconds:20,//20秒倒计时跳转到群聊
      gid:0,
      gname:"",
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.token = this.$route.query.token;
    this.activateGroupChat();
  },
  methods: {
    activateGroupChat() {
      var that = this;
      postConfirmCreate(this.id, this.token, (result) => {
        if (result && result.succeed) {
          that.showSuccess = true;
          that.gid = result.gid;
          that.gname = result.gname;
          
          var countdown = setInterval(() => {
            this.seconds--;
            if (this.seconds === 0) {
              clearInterval(countdown);
              this.redirectToGroupChat();
            }
          }, 1000); // 每秒更新一次倒计时

        } else {
          that.showError = true;
          that.errorMessage = result.message
        }
      });
    },
    redirectToGroupChat() {
      var urlid = this.gid.toString(36); 
      let routeData = this.$router.resolve({
          name: 'chatRoom',
          params: {id: urlid}
      });
      window.location.href = routeData.href;
    }
  }
};
</script>

<style>

.container {
  max-width: 800px;
  margin: 100px auto;
  padding: 20px;
}

.container div{
  margin: 5px;;
}

.gname{
  color:#67c511;
  font-weight: bold;
  font-size: 28px;
}

.success-message {
  color:#67c511;
  padding: 20px;
}
.error-message{
  color:#e84335;
  padding: 20px;
}

.succeedicon, .failedicon {
  width: 50px;
  height: 50px;
}

.btn {
  padding: 14px 50px;
  margin-top: 50px;;
  border-radius: 50px;
  cursor: pointer;
}

.button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .container {
    padding: 10px;
  }
}

</style>
