<template>
  <div class = "background">
  <div class="creatorpage">
    <div @click="backHome" class="home-btn">
      <img class="home-img" src="../assets/homebutton.png"/>
      <!-- <div>返回首页</div> -->
    </div>
    <LoadingVue v-if="pageLoading"></LoadingVue> <!-- 加载动画 -->
    <div v-if="!pageLoading" :style="{textAlign:'center'}">
      <div class="header">
        <h1>创建付费群，兑奖品与现金</h1>
        <button class="apply-button" @click="createdialog = true">+ 申请创建付费群</button>
      </div>
      <!-- 总积分模块 -->
      <div class="score-module">
        <div class="score-header">
          <div class="score-title">我的积分</div>
          <div v-if="islogin" class="score-email"><div>({{ displayEmail }}<button @click="handleLogout">退出</button>)</div></div>
          <button v-if="islogin" class="score-detail" @click="pointslistdialog = true">积分详情 ></button>
        </div>
        <div v-if="!islogin" class="score-tip">
          <button class="score-login" @click="logindialog = true">点击此处登录</button>
        </div>
        <div v-else class="score-content">
          <div class="score-item" @click="pointslistdialog = true">
            <button >{{ homeData.points }}</button>
            <p>可用积分</p>
          </div>
          <div class="score-item" @click="pointslistdialog = true">
            <button >{{ homeData.invitedcount }}</button>
            <p>邀请人数</p>
          </div>
          <div class="score-item" @click="grouplistdialog = true">
            <button  >{{ homeData.groupcount }}</button>
            <p>生效群聊</p>
          </div>
        </div>
      </div>
      <!-- 奖品兑换模块 -->
      <div class = "gift-module">
        <div class="gift-header">
          <div>积分兑换</div>
          <button class="gift-detail" @click="exchangelistdialog = true">兑换详情 ></button>
        </div>
        <div class="gift-content">
          <div class="gift-item">
            <img class="gift-icon"  src="../assets/qb.png"/>
            <div class="gift-name">10QB</div>
            <button class = "gift-btn" @click="exchange('10QB')">10积分</button>
          </div>
          <div class="gift-item">
            <img class="gift-icon"  src="../assets/100jd.png"/>
            <div class="gift-name">100京东卡</div>
            <button class = "gift-btn" @click="exchange(('100JD'))">100积分</button>
          </div>
          <div class="gift-item">
            <img class="gift-icon"  src="../assets/500jd.png"/>
            <div class="gift-name">500京东卡</div>
            <button class = "gift-btn" @click="exchange('500JD')">500积分</button>
          </div>
          <!-- 兑换现金时先提示需要1000积分以上，最后提示目前暂未开放，请兑换其他奖品-->
          <div class="gift-item">
            <img class="gift-icon"  src="../assets/cash.png"/>
            <div class="gift-name">兑换现金</div>
            <button class = "gift-btn" @click="exchange('CASH')">1积分=1元</button>
          </div>
        </div>
      </div>
      <!-- 活动介绍模块 -->
      <div class = "intro-module">
        <div class="intro-header">
          创建付费群，轻松3步拿奖品
        </div>
        <div class="intro-item">
          <div class="intro-title">1、免费创建一个付费匿名聊天群</div>
          <div class="intro-text">使用邮箱无门槛申请，每个邮箱可申请10个群</div>
        </div>
        <div class="intro-item">
          <div class="intro-title">2、邀请他人进群，免费获得积分</div>
          <div class="intro-text">他人支付进群，你可获得积分，1元=0.5积分</div>
        </div>
        <div class="intro-item">
          <div class="intro-title">3、使用积分兑换丰厚奖励！</div>
          <div class="intro-text">积分可随时在当前页兑换奖品或现金</div>
        </div>
      </div>
      <!-- 免责声明 -->
      <div class = "info-module">
        <div>免责声明：请勿通过本平台开展违法违规活动，任何通过违规或非正常手段获得积分的账号将会被永久封禁，积分自动清零。<br/>
创建付费群活动处于内测阶段，平台可能在任何时候调整活动规则，包括但不限于调整积分规则、调整兑换规则或停止活动等，所有规则变动都将通过本页面至少提前7天告知，请随时关注本页面，并及时将积分兑换成奖品。如有更多问题，请通过邮箱zaiciqunliao@163.com与我们联系。</div>
      </div>
      
      <!-- 以下为弹窗：-->
      <!-- 创建群聊弹窗 -->
      <createDrawer :visible="createdialog" @close="visible => this.createdialog = visible" @cancelForm="cancelForm" @showSuccessDrawer="showSuccessDrawer"></createDrawer>

      <!-- 修改群聊弹窗 -->
      <editDrawer :visible="editgroupdialog" @close="visible => this.editgroupdialog = visible" :formData="editgroupform" @cancelForm="cancelForm" @updateList="refreshGroupList"></editDrawer>
      

      <!-- 登录弹窗 -->
      <loginDrawer :visible="logindialog" @close="visible => this.logindialog = visible" @cancelForm="cancelForm" @handleGetHomeData="handleGetHomeData"></loginDrawer>

      <!-- 兑换奖品弹窗 -->
      <exchangeDrawer :visible="exchangedialog" :formData="exchangeform" @close="visible => this.exchangedialog = visible" @cancelForm="cancelForm" @refreshHomeData="handleGetHomeData"></exchangeDrawer>
       

      <!-- 积分详情列表弹窗 -->
      <pointsListDrawer :visible="pointslistdialog"  @close="visible => this.pointslistdialog = visible"  ></pointsListDrawer>


      <!-- 兑换记录列表弹窗 -->
      <exchangeListDrawer :visible="exchangelistdialog"  @close="visible => this.exchangelistdialog = visible"  ></exchangeListDrawer>

      <!-- 群聊列表弹窗 -->
      <groupListDrawer :visible="grouplistdialog" ref="groupListDrawer"  @close="visible => this.grouplistdialog = visible"  @handleGroupEdit="handleGroupEdit"></groupListDrawer>

      <!-- 成功之后的提示弹窗 -->
      <successTipDrawer :visible="successDialog"  @close="visible => this.successDialog = visible" :title="successDrawerTitle" :text="successDrawerText"></successTipDrawer>
      
    </div><!-- 非Loading状态-->
  </div>
  </div>
  
</template>
  
  
  <script>
  import LoadingVue from "@/components/LoadingPage.vue"
  import createDrawer from "@/components/createPayDrawers/createDrawer"
  import editDrawer from "@/components/createPayDrawers/editDrawer"
  import loginDrawer from "@/components/createPayDrawers/loginDrawer"
  import exchangeDrawer from "@/components/createPayDrawers/exchangeDrawer"
  import pointsListDrawer from "@/components/createPayDrawers/pointsListDrawer"
  import exchangeListDrawer from "@/components/createPayDrawers/exchangeListDrawer"
  import groupListDrawer from "@/components/createPayDrawers/groupListDrawer"
  import successTipDrawer from "@/components/createPayDrawers/successTipDrawer"
  import {getLoginEmail,logout,postGetHomeData} from "../func/creatorHandler";

  export default {
    data() {
      return {
        pageLoading:true,//页面全屏幕的独立Loading，在加载完成前展示
        createdialog:false,//创建付费群弹窗
        editgroupdialog:false,//设置付费群弹窗
        logindialog:false,//登录弹窗
        exchangedialog:false,//兑换Q币奖品的弹窗
        pointslistdialog:false,//积分列表弹窗
        exchangelistdialog:false,//兑换列表弹窗
        grouplistdialog:false,//群列表详情弹窗

        successDialog: false,//对创建成功，兑换成功等情况，需要相应的提示drawer
        successDrawerTitle:"",//成功drawer的正文
        successDrawerText:"",//成功drawer的正文

        editgroupform:{
          gname: '',
          price: '',
          intro: '',
        },
        
        //兑换数据
        exchangeform:{
          giftname:"",//礼物名，如"100JD"
          cashcents:0,//兑换的现金金额，暂时没用上
          receivingAccount:"",//收款账号，如QQ号或收现金账号
          comfirmRecevingAccount:"",//确认收款账号
          giftstring:"",//礼物外显名，如“100京东卡”，仅前端用,
          type: "", // QB JD cash
        },
        
        islogin:false, //标识是否登录
        loading:false, //标识是否显示drawer的loading
        //全局的数据
        loginEmail:"",//登录后更新email
        homeData:{
          points:0,
          invitedcount:0,
          groupcount:0
        }
        // ...
      };
    },
    components:{
        LoadingVue,
        createDrawer,
        editDrawer,
        loginDrawer,
        exchangeDrawer,
        pointsListDrawer,
        exchangeListDrawer,
        groupListDrawer,
        successTipDrawer
    },
    mounted() {
      this.pageLoading = false; // 数据加载完成后更新状态
    },
    created(){
      //加载成功后刷新首页数据
      if(getLoginEmail()){
          //先看看本地有无登录状态记录

          //登录成功后获取用户数据
          this.handleGetHomeData();
      }
      else{
          this.islogin = false;
          this.loginEmail = "";
      }
    },
    computed: {
      displayEmail() {
        if (!this.loginEmail) return ''; // 如果loginEmail为空，则直接返回空字符串
        const atIndex = this.loginEmail.indexOf('@'); // 找到@符号的位置
        if (atIndex === -1) return this.loginEmail; // 如果没有找到@符号，直接返回原邮箱地址

        const localPart = this.loginEmail.substring(0, atIndex); // 获取本地部分
        const domainPart = this.loginEmail.substring(atIndex); // 获取域部分

        // 如果本地部分长度大于4，才需要缩略展示，否则直接返回原邮箱地址
        if (localPart.length > 4) {
          const abbreviatedLocalPart = `${localPart.substring(0, 2)}..${localPart.substring(localPart.length - 2)}`;
          return `${abbreviatedLocalPart}${domainPart}`; // 组合并返回处理后的邮箱地址
        } else {
          return this.loginEmail;
        }
      },
    },
    methods: {
      cancelForm(drawerID) {
        this.loading = false;
        this[drawerID] = false;
        clearTimeout(this.timer);
      },
      //处理登出
      handleLogout() {
        logout();
        this.islogin = false;
        // 登出成功后刷新页面
        window.location.reload();
      },
      handleGetHomeData(){
        //获取或刷新首页数据 
        this.logindialog = false;
        this.loginEmail = getLoginEmail();
        if(this.loginEmail){
          this.islogin = true;
        }else{
          return;//未登录情况下不能请求首页数据
        }

        var that = this
        postGetHomeData((result) => {
          if (result.succeed) {
            console.log("刷新首页数据");
            // 获取成功
            that.homeData.points = result.points
            that.homeData.invitedcount = result.invitedcount
            that.homeData.groupcount = result.groupcount
          } else {
            // 获取失败
            this.$message.error(result.message || '获取账号数据失败，请刷新页面重试');
          }
        });
      },
      //弹窗中提交表单时，处理表单数据
      
      //该方法在grouplistDrawer中被调用
      handleGroupEdit(row){
        if(row !=""){
          this.editgroupform.gname = row.gname
          this.editgroupform.price = row.price/100 //价格默认为分，需变为单位为元
          this.editgroupform.intro = row.intro
          this.editgroupdialog = true
        }else{
          this.createdialog = false
          this.editgroupform.gname = ""
          this.editgroupform.price = 0
          this.editgroupform.intro = ""
        }
      },
      //editgroup后需要刷新grouplistdrawer的列表
      refreshGroupList() {
        // 假设groupListDrawer组件通过ref属性可以被访问
        this.$refs.groupListDrawer.getGroupList();
      },

      //创建成功显示成功提示框
      showSuccessDrawer(data){
        this.successDialog = true; // 显示提示drawer
        this.successDrawerTitle = data.title;
        this.successDrawerText= data.text;
      },
      
      //以下为获取列表数据的接口
      //以下均有html页面触发，先请求数据再展开drawer
      //TODO:以下3个list，缺少滚动后追加数据的交互，需要迭代，先测试数据再来搞

      //点击兑换礼物时触发对应弹窗
      exchange(giftname){
         //如果当前用户未登录
         if(this.islogin == false){
            this.$message.error("请先登录再进行兑换");
            return;
          }
        //先根据兑换礼物不同，决定弹什么兑换弹窗
        //记录当前兑换的礼物名
        this.exchangeform.giftname = giftname
        if(giftname == "10QB"){
          this.exchangeform.giftstring = "10Q币"
          this.exchangeform.cashcents = 1000
          this.exchangeform.type = "QB"
          this.exchangedialog = true
        }
        else if(giftname == "100JD" || giftname == "500JD"){
          this.exchangeform.type = "JD"
          if(giftname == "100JD"){
            this.exchangeform.giftstring = "100京东卡"
            this.exchangeform.cashcents = 10000
          }
          else if(giftname == "500JD"){
            this.exchangeform.giftstring = "500京东卡"
            this.exchangeform.cashcents = 50000
          }
          this.exchangedialog = true
        }
        else if(giftname == "CASH"){
          //如果当前用户未登录
          if(this.islogin == false){
            this.$message.error("请先登录再进行兑换");
            return;
          }
          //如果当前用户积分不足1000
          if(this.homeData.points < 1000){
            this.$message.error("需至少1000积分才可兑换");
          }else{
            //后续迭代，TODO:提现流程需要完善才行
            this.$message.error("当前现金兑换额度已满，请兑换其他奖品哦~");
          }
        }else{
          this.$message.error("系统错误，请重试");
        }
        
      },

      backHome(){
        //this.$router.push('/');
        window.open('/', '_blank');
      },

    },
  };
  </script>
  
  <style lang="scss" scoped>
  /*+++++++主页面样式表：+++++++ */
  .background{
    background: linear-gradient(#FACF00,#FAD033, #F2F2F2, #F2F2F2,#F2F2F2);
    overflow-y: scroll; /* 垂直方向滚动 */
    max-height: 100vh;
    height:100dvh;
  }
  .creatorpage {
    //overflow-y: scroll; /* 垂直方向滚动 */
    padding: 0 3%;
    text-align: left;
  }
  @media (min-width: 769px) {
  .creatorpage {
    max-width: 480px;
    margin: 0 auto; /* 水平居中 */
  }

  ::v-deep .el-drawer__body{
      width: 480px;
      margin: 0 auto;
    }

}

  .home-btn{
    display: inline-block;
    position: relative;
    left: -3%;
    top: 0;
    background-color: #ffffff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 2px 6px 2px 5px;
    cursor: pointer;

    .home-img{
      width: 30px;
      height: 30px;
    }

    div{
      color: #facf00;
      font-size: 12px;
      margin-top: -5px;
    }
  }
  .header{
    //position: relative;
    text-align: center;
    h1{
      color: #ffffff;
      font-size: 24px;
      margin-top: 0px;
    }

    .apply-button{
      width: 165px;
      height: 43px;
      background: inherit;
      background-color: rgba(255, 255, 255, 1);
      border: none;
      border-radius: 200px;
      font-weight: 400;
      font-style: normal;
      font-size: 16px;
      color: #FACF00;
      margin-bottom: 10px;
      cursor: pointer;
    }
  }
  .score-module, .gift-module, .intro-module{
    width: calc(100% - 40px);
    padding: 20px 20px;
    margin-top: 20px;
    background-color: rgba(255, 255, 255, 1);
    border: none;
    border-radius: 12px;
    text-align: left;

    button{
      border: none;
      background: none;
      cursor: pointer;
    }
  }
  .info-module {
    width: calc(100% - 8%);
    padding: 20px 4%;
    text-align: left;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    color: #999999;
  }
  .score-header{
    display: flex;

    .score-title{
      color: #333333;
      font-size: 16px;
      font-weight: bold;
    }
    .score-email, .score-detail{
      font-size: 14px;
      color: #A1A4A9;
    }
    .score-email{
      flex:1;
      margin-left: 5px;
      line-height: 23px;
      
      button{
        font-size: 14px;
        color: #A1A4A9;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  .score-tip{
    text-align: center;
    margin: 40px 0;
    button{
      font-size: 16px;
      text-decoration: underline;
      color: #cccccc;
      cursor: pointer;
    }
  }
  .score-content{
    display: flex;
    margin-top: 20px;
    .score-item{
      flex: 1;
      text-align: center;
      cursor:pointer;

      &:nth-child(2){
        border-left: 1px solid #eef0f8;
        border-right: 1px solid #eef0f8;
      }

      button{
        font-size: 22px;
        font-weight: bold;
        color: #333;
        cursor: pointer;
      }
      p{
        font-size: 14px;
        color: #A1A4A9;
        margin: 5px 0 10px;
      }
    }
  }
  .gift-module{
    .gift-header{
      display:flex;
      margin-top: 10px;

      div{
        flex: 1;
        color: #333333;
        font-size: 16px;
        font-weight: bold;
      }
      button{
        font-size: 14px;
        color: #A1A4A9;
        cursor: pointer;
      }
    }
    .gift-content{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 20px;
      padding-top: 10px;
      
      .gift-item{
        margin-top: 20px;
      }
      .gift-icon{
        width: 78px;
        height: 56px;
      }
      .gift-name{
        color: #333333;
        font-size: 12px;
        font-weight: bold;
        text-align: center;
        margin: 0px 0 10px;
      }
      .gift-btn{
        width: 100%;
        height: 28px;
        background-color: #FFFFFF;
        font-size: 12px;
        font-weight: bold;
        color: #FACF00;
        border: 1px solid #FACF00;
        border-radius: 20px;
        cursor:pointer;
      }
    }
  }
  .intro-module{
    .intro-header{
      font-weight: bold;
      margin: 10px 0 20px;
    }
    .intro-header, .intro-title{
      font-size: 16px;
      color: #333333;
    }
    .intro-item{
      padding: 0px 0 10px;
    }
    .intro-text{
      font-size: 14px;
      color: #BDC0C8;
      margin-left: 26px;
      margin-top: 2px;
      margin-bottom: 2px;
    }
  }
  
  </style>
  