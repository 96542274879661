<template>
    <div class="wrap">
        <div class="avatar" :style="{background: bgColor}" v-on:click="changename"><div class="avatar-text">{{info.senderAvatar}}</div></div>
        <div class="chat-nickname" v-on:dblclick="copyuid">{{info.senderName}}</div>
        <div class="message-wrap" v-on:dblclick="copymid">
            <div class="message" style="white-space:pre-wrap" v-if="info.type == 1">{{info.msg}}</div>
            <div class="message" v-if="info.type == 2">
                <el-image 
                    class="imgmsg"
                    :src="info.msg+'?x-oss-process=image/resize,h_80,m_lfit/format,jpg'" 
                    :preview-src-list="[info.msg+'?x-oss-process=image/resize,h_4096,m_lfit//quality,q_90/format,jpg']">
                </el-image>
            </div>
            <!--<button class="like" >{{info.ifLike ? '已点赞' : '点赞'}}</button>-->
            <img class="like-btn" v-if="info.ifLike" src="../assets/liked.png"/>
            <img class="like-btn" v-else src="../assets/like.png" @click="clicklike"/>
            <div class="like_num" v-if="info.ifLike">{{info.likeNum}}</div>
        </div>
    </div>
</template>

<script>

//import {getCookie} from "../func/util"
//import globaldata from "@/config/globaldata"

import {postChangeName} from "../func/requestHandler" 
import {getCookie} from "../func/util"
export default {
    
    props: {
        info: {
            /**info结构：
             *  msg:xxxx,
                msgId:123,
                type:1,
                senderName:km,
                senderAvatar:k,
                senderId:12345,
                likeNum:0,
                ifLike:true
            },
             */
            type: Object,
            default: new Object()
        },
        gid: Number
    },
    computed:{
        bgColor(){
            if( !this.info || !this.info.senderId ) return "#CBABF3";
            //设置当前用户头像颜色,目前有4种随机颜色
            let colors = ['rgb( 162, 211, 133)',
                        'rgb( 141, 198, 251)',
                        'rgb( 203, 171, 243)',
                        'rgb( 246, 202, 51)']
            //根据当前用户id、群聊id 及 日期，算出今天当前用户在群里的唯一的颜色值（同一用户在同一个群，每22222秒换一次头像，数值是随便拍的）
            let hashnumber = this.info.senderId + this.gid + Date.now()/(22222*1000)
            return colors[ Math.floor(hashnumber % 4)]
        }
    },
    methods: {
        clicklike(){
            this.$emit("like", this.info.msgId)
        },
        async changename(){
            if(this.info.senderId != getCookie('uid')){
                //如果发送者的uid不是当前用户uid，不予修改
                return
            }

            var that = this
            //var oldname = this.info.senderName;
            
            that.$prompt( '','修改昵称', {
            confirmButtonText: '确认修改',
            inputPlaceholder:"输入昵称，不超过10个字",
            inputPattern:/^.{1,10}$/,
            inputErrorMessage:"昵称不能超过10个字符",
            cancelButtonText: '取消',
            closeOnClickModal:true,
            center:true
            }).then(async ({ value }) => {
                //如果输入为空，不做任何处理，恢复成旧名字
                var result = await postChangeName(value)
                if(result.succeed){
                    //修改成功
                    that.$message.success('修改成功！下次发言或重新进群后生效');
                }else{
                    that.$message.error(result.message);
                }
                
            }).catch(() => {
                //不做任何事  
            });

        },
        copyuid(){
            var input = document.createElement("input"); // 创建input对象
            input.value = this.info.senderId; // 设置复制内容
            document.body.appendChild(input); // 添加临时实例
            input.select(); // 选择实例内容
            document.execCommand("Copy"); // 执行复制
            document.body.removeChild(input); // 删除临时实例
        },
        copymid(){
            var input = document.createElement("input"); // 创建input对象
            input.value = this.info.msgId; // 设置复制内容
            document.body.appendChild(input); // 添加临时实例
            input.select(); // 选择实例内容
            document.execCommand("Copy"); // 执行复制
            document.body.removeChild(input); // 删除临时实例
        }
    }
}
</script>

<style scoped>
.wrap{
        width: calc(100% - 115px);
        position: relative;
        text-align: left;
        margin: 0 40px 25px 70px;
    }

    .avatar{
        border-radius: 50%;
        background-color: #CBABF3;
        width: 50px;
        height: 50px;
        position: absolute;
        left: -60px;
    }

    .avatar-text{
        width: 50px;
        text-align: center;
        position: absolute;
        top: 12px;
        font-size: 20px;
        font-weight: bold;
        color: #fff;
        user-select: none; /* 禁用文字选择 */
    }

@media only screen and (max-width: 1023px) {
    
    .imgmsg{
        max-width: 100px;
        min-width: 40px;
        max-height: 100px;
        height: auto;
    }
    
    
    .chat-nickname{
        font-size: 14px;
        color: #888888;
        position: absolute;
        left: 2%;
        cursor: default;
    }
    .message-wrap{
        position: relative;
        display: inline-block;
        /*margin-left: 10px;*/
        margin-top: 20px;
    }
    .message{
        background: #fff;
        border-radius: 0 10px 10px 10px;
        padding: 10px;
        /*position: absolute;
        top: 40%;
        left: 5%;*/
        display: inline-block;
        word-break: break-word;
    }
    .like-btn{
        width: 15px;
        height: 15px;
        position: absolute;
        right: -20px;
        top: 2px;
        user-select: none; /* 禁用文字选择 */
    }
    .like_num{
        position: absolute;
        top: 0px;
        right: -30px;
        font-size: 15px;
        color: #ED6D6C;
        user-select: none; /* 禁用文字选择 */
    }
}
@media only screen and (min-width: 1024px) {
    .imgmsg{
        max-width: 100px;
        min-width: 40px;
        max-height: 100px;
        height: auto;
    }
    /*.avatar{
        border-radius: 50%;
        /*background-color: #CBABF3;*/
       /* width: 41px;
        height: 41px;
        position: absolute;
    }
    .avatar-text{
        width: 41px;
        text-align: center;
        position: absolute;
        top: 20%;
        font-size: 18px;
        color: #fff;
    }*/
    .chat-nickname{
        font-size: 12px;
        color: #888888;
        position: absolute;
        left: 10px;
    }
    .message-wrap{
        position: relative;
        display: inline-block;
        /*margin-left: 10px;*/
        margin-top: 20px;
    }
    .message{
        background: #fff;
        border-radius: 0 10px 10px 10px;
        padding: 10px;
        /*position: absolute;
        top: 40%;
        left: 5%;*/
        display: inline-block;
        word-break: break-word;
    }
    .like-btn{
        width: 15px;
        height: 15px;
        position: absolute;
        right: -20px;
        top: 2px;
        user-select: none; /* 禁用文字选择 */
    }
    .like_num{
        position: absolute;
        top: 0px;
        right: -30px;
        font-size: 15px;
        color: #ED6D6C;
        user-select: none; /* 禁用文字选择 */
    }
}
</style>