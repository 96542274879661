<template>
    <el-drawer
        :title="'兑换' + exchangeform.giftstring"
        :before-close="handleClose"
        :visible="visible"
        direction="btt"
        custom-class="drawerclass"
        size= "auto"
        ref="drawer"
        >
        <div class="drawerintro">{{ title }}</div>
        <div class="drawerbody">
          <el-form v-if="type == 'QB'">
            <el-form-item label="QQ号" :label-width="formLabelWidth" >
              <el-input v-model="exchangeform.receivingAccount" type="number" autocomplete="off" clearable placeholder="输入需要充值的QQ号"></el-input>
            </el-form-item>
            <el-form-item label="确认QQ" :label-width="formLabelWidth" >
              <el-input v-model="exchangeform.comfirmRecevingAccount" type="number"  show-word-limit autocomplete="off" placeholder="再次输入需要充值的QQ号" clearable></el-input>
            </el-form-item>
          </el-form>
          <el-form v-if="type == 'JD'">
            <el-form-item label="管理邮箱" :label-width="formLabelWidth" >
              <el-input v-model="exchangeform.receivingAccount" autocomplete="off" clearable placeholder="输入当前的管理邮箱"></el-input>
            </el-form-item>
          </el-form>
          <div class="demo-drawer__footer">
            <el-button @click="cancelForm">取 消</el-button>
            <el-button type="primary" @click="handleExchange" :loading="loading">{{ loading ? '提交中 ...' : '立即兑换('+(exchangeform.cashcents/100)+'积分)' }}</el-button>
          </div>
        </div>
      </el-drawer>
</template>

<script>
import {getLoginEmail, postExchangeGift} from "@/func/creatorHandler";

export default{
    props: ['visible', 'formData'],
    data(){
        return {
            formLabelWidth: '20%',
            loading: false,
            exchangeform:{
              giftname:"",//礼物名，如"100JD"
              cashcents:0,//兑换的现金金额，暂时没用上
              receivingAccount:"",//收款账号，如QQ号或收现金账号
              comfirmRecevingAccount:"",//确认收款账号
              giftstring:"",//礼物外显名，如“100京东卡”，仅前端用
            },
        };
    },
    computed: {
      type(){
        return this.formData ? this.formData.type : '';
      },
      title(){
        switch(this.type){
          case 'QB':
            return '兑换后将于7天内自动充值到账';
          case 'JD':
            return '兑换后，京东卡密将于7天内发放到管理邮箱';
          default:
            return '';
        }
      }
    },
    watch: {
      formData:{
        handler(newVal){
          this.exchangeform = {...newVal};
        },
        deep: true
      }
    },
    methods: {
      handleClose(done) {
        done()
        this.$emit("close", false)
      },
        cancelForm(){
            this.$emit("cancelForm", 'exchangedialog')
        },
        handleExchange() {
          //京东卡的情况，判断输入的邮箱是否为当前登录邮箱
          if(this.exchangeform.giftname.includes("JD")){
            if(this.exchangeform.receivingAccount != getLoginEmail()){
              this.$message.error('管理邮箱有误，请填写当前已登录的管理邮箱地址');
              return;
            }
          }
          if (this.exchangeform.giftname == "10QB") {
              // 使用正则表达式检查QQ号格式
              const qqNumberPattern = /^[1-9][0-9]{4,10}$/;
              const isQQNumberValid = qqNumberPattern.test(this.exchangeform.receivingAccount);

              if (!isQQNumberValid) {
                  this.$message.error('输入的QQ号格式不正确，请重试');
                  return;
              }
          }

          if(this.exchangeform.giftname == "10QB" && this.exchangeform.receivingAccount != this.exchangeform.comfirmRecevingAccount){
            this.$message.error('两次输入的QQ号不一致，请检查后重试');
              return;
          }
          this.loading = true; // 开始加载
          const gift = this.exchangeform.giftname
          const cashcents = this.exchangeform.cashcents
          const receivingAccount = this.exchangeform.receivingAccount
          postExchangeGift(gift,cashcents,receivingAccount, (result) => {
            this.loading = false; // 结束加载
            if (result.succeed) {
              // 兑换成功
              this.$emit('refreshHomeData')//刷新首页数据
              this.$message.success(result.message);
              this.$emit("close", false)
              
            } else {
              // 兑换失败
              this.$message.error(result.message || '系统错误，请重试');
            }
          });
      },
    }
}
</script>

<style scoped>
/*++++++弹窗样式表：++++++++*/
::v-deep .drawerclass .el-drawer__header {
    margin-bottom: 5px;
    font-size: 20px;
    color: #333333;
    padding-right:0px;
    margin-left: 20px;
  } 
  .drawerbody{
    width: 100%;
    padding-top:20px;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 40px;
    line-height: 20px;
  }
  .drawerintro{
    margin:0 10% 5px 10%;
    font-size: 12px;
    color:#333333;
  }

  /* Drawer 基本样式调整 */
.succeeddrawer {
  padding: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
  line-height: 1.5;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

/* 成功提示文本样式 */
.succeeddrawer div {
  margin-bottom: 20px;
  font-size: 16px;
  text-align: center;
}

/* 成功图标动画 */
@keyframes popIn {
  0% {
    transform: scale(0); /* 开始时图标缩放为0 */
    opacity: 0; /* 开始时图标完全透明 */
  }
  50% {
    transform: scale(1.2); /* 中途图标放大到比原始尺寸稍大 */
    opacity: 1; /* 中途图标完全不透明 */
  }
  100% {
    transform: scale(1); /* 结束时图标恢复到原始尺寸 */
  }
}
/* 成功图标样式 */
.succeedicon {
  display: block;
  margin: 40px auto 30px; /* 增加下边距以增大图标与文案之间的间隙 */
  width: 60px; /* 根据需要调整图标大小 */
  height: auto;
  animation: popIn 0.6s ease-out; /* 应用动画 */
}
.succeedtext{
  margin:0 8% 0 8%;
}
/* 按钮样式 */
.succeeddrawer .el-button {
  display: block;
  margin: 0 auto;
  padding: 15px 40px;
  margin:30px auto 40px auto;
  font-size: 14px;
  color: #fff;
  background-color: #409EFF;
  border-color: #409EFF;
  border-radius: 30px; /* 圆角按钮 */
  transition: background-color .3s, border-color .3s, color .3s;
}

.succeeddrawer .el-button:hover {
  background-color: #66b1ff;
  border-color: #66b1ff;
  color: #fff;
}

/* Drawer 标题样式 */
.succeeddrawer .el-drawer__header {
  font-size: 18px;
  color: #333;
  text-align: center;
  border-bottom: 1px solid #ebeef5;
}
</style>