import Vue from 'vue'
import App from './App.vue'
import router from './router'
import MetaInfo from 'vue-meta-info'
import elTableInfiniteScroll from 'el-table-infinite-scroll'
Vue.use(elTableInfiniteScroll)
import {Message, Notification, MessageBox, Image,Drawer,Button,Input,Form,FormItem,Table,TableColumn,Dropdown,DropdownMenu,DropdownItem,} from 'element-ui'
/*npm install --save el-table-infinite-scroll@2
文档详见：https://www.npmjs.com/package/el-table-infinite-scroll
*/
//Vue.use(Message)
//Vue.use(Notification)
Vue.prototype.$message = Message;
Vue.prototype.$notify = Notification;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.component(Message.name, Message)
Vue.component(Notification.name, Notification)
Vue.use(Image)
Vue.use(MetaInfo)
Vue.use(Drawer)
Vue.use(Button)
Vue.use(Input)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)

Vue.config.productionTip = false

new Vue({
  el: '#app',
  render: h => h(App),
  router,
  mounted () {
    //document.dispatchEvent(new Event('render-event'))
  }
})
//.$mount('#app')
